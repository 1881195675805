import { HasDiscountBeenApplied } from '../helpers/utilities'

export class GoogleTagManager {
  static pushDataLayerEvent(name, props, callback = false) {
    if (!window.google_tag_manager && callback) {
      callback()
      return
    }

    if (name) {
      let payload = props ? { event: name, ...props } : { event: name }
      payload.userLoginStatus = document.querySelector('[data-logged-in]') ? 1 : 0
      payload.discountApplied = HasDiscountBeenApplied()

      if (callback) {
        payload.eventTimeout = 5000
        payload.eventCallback = function (id) {
          if (id == window.ENV['GOOGLE_TAG_MANAGER_ID']) {
            callback()
          }
        }
      }

      window.dataLayer.push(payload)
    }
  }
}

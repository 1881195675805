/**
 * ⚠️ Shared module that's in use in both Volders and Aboalarm.
 * ⚠️ This module is used in both the login and password reset forms.
 */

import { InputVincent } from '../components/input_vincent'
import { InputAboalarm } from '../aboalarm/components/input_aboalarm.js'
import { IsValidEmailAddress } from '../helpers/utilities'

export class UnifiedSignupForm {
  constructor() {
    this.form = document.querySelector('[data-context="unified-signup-form"]')
    this.formSubmitted = false
    this.currentMarket = document.body.dataset.currentMarket
    if (!this.form) return

    this.initFields()
  }

  initFields() {
    this.fields = {}
    this.submitButton = this.form.querySelector('button[type="submit"]')
    this.submitButton.classList.remove('btn--hasLoadingState')

    this.initField('email', () => {
      this.validateEmail()
    })
    this.captureSubmit()
  }

  initField(name, callback) {
    this.fields[name] =
      this.currentMarket === 'aboalarm'
        ? new InputAboalarm(`${name}`, callback)
        : new InputVincent(`SignupForm-${name}`, callback)
  }

  validateEmail() {
    let input = this.fields.email

    if (IsValidEmailAddress(input.value())) {
      input.hideError()
      input.showValid()

      return true
    }

    if (this.formSubmitted) {
      input.showError(I18n.t('frontend.unified_signup_page.errors.email'))
    }

    return false
  }

  captureSubmit() {
    this.form.addEventListener('submit', (e) => {
      this.formSubmitted = true
      if (!this.validateEmail()) {
        e.preventDefault()
        return false
      } else {
        this.spinButton()
      }
    })
  }

  spinButton() {
    this.submitButton.disabled = true
    this.submitButton.classList.add('btn--hasLoadingState')
  }
}

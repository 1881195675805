import { InputAboalarm } from '../components/input_aboalarm.js'
import { IsValidEmailAddress } from '../../helpers/utilities'

export class NewsletterUnsubscribe {
  constructor() {
    this.el = document.querySelector('[data-context=newsletter-form]')
    if (!this.el) return

    this.pristine = true
    this.initFields()
    this.captureSubmit()
  }

  initFields() {
    this.fields = {}
    this.initField('email', () => {
      this.validateEmailFormat()
    })

    this.initField('newsletter-optout', () => {
      this.validateCheckbox()
    })
  }

  initField(name, callback) {
    this.fields[name] = new InputAboalarm(`${name}`, callback)
  }

  captureSubmit() {
    this.el.addEventListener('submit', (e) => {
      this.submit(e)
    })
  }

  submit(e) {
    this.pristine = false
    let formIsValid = this.validateForm()
    if (formIsValid) {
      if (e.target !== this.el) this.requestFormSubmit()
    } else {
      e.preventDefault()
      return false
    }
  }

  validateForm() {
    return this.validateEmailFormat() && this.validateCheckbox()
  }

  requestFormSubmit() {
    if (typeof this.el.requestSubmit == 'function') {
      this.el.requestSubmit()
    } else {
      this.el.submit()
    }
  }

  validateCheckbox() {
    const field = this.fields['newsletter-optout']
    const isChecked = this.fields['newsletter-optout'].value()
    if (isChecked) {
      field.hideError()
      return true
    }

    if (this.pristine) {
      field.hideValid()
    } else {
      field.showError()
    }
    return false
  }

  validateEmailFormat() {
    const field = this.fields.email
    const trimmedInputValue = field.value().trim()

    if (IsValidEmailAddress(trimmedInputValue)) {
      field.hideError()
      field.showValid()
      field.inputEl.value = trimmedInputValue
      return true
    }

    if (this.pristine) {
      field.hideValid()
    } else {
      field.showError(
        I18n.t('frontend.cancellation_form.errors.presence', {
          field_name: field.label(),
        })
      )
    }

    return false
  }
}

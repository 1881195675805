import { ShowElement, HideElement } from '../helpers/element-modifiers'
import { HasDiscountBeenApplied } from '../../helpers/utilities'
import { Amplitude } from '../../integrations/amplitude'
import { GoogleTagManager } from '../../integrations/google_tag_manager'
import { Voucher } from '../../cancellation/voucher'
import { VWO } from '../../integrations/vwo'
import {
  sessionStorageAvailable,
  sessionStorageGetItem,
  sessionStorageRemoveItem,
} from '../../vendor/storage_utilities'

export class CheckoutPage {
  constructor() {
    this.el = document.querySelector('[data-context="checkout-page"]')
    if (!this.el) return

    this.introduceKeyElements()
    this.introduceProps()
    this.attachEventListeners()
    this.trackCheckoutPageInitialize()
    this.trackDeleteMyDataConsent()
    this.listenVoucherAmountChangedEvent()
    this.initializeMoneyBackTracking()
    this.initSocialProofExperiment()
    this.initProductPriceExperiment()
    this.initSelectedPackageDetailView()
    this.modifyModalRemovePreviewExperiment()
  }

  modifyModalRemovePreviewExperiment() {
    if (!sessionStorageAvailable()) return
    if (sessionStorageGetItem('RemovePreviewExperiment') === 'active') {
      const button = document.querySelector('[data-context="back-button"]')
      button.querySelector('span').innerText = 'Zurück'
    }
  }

  initSelectedPackageDetailView() {
    window.addEventListener('aboalarm:selectedPackageDetailView', () => {
      const productsInput = document.querySelectorAll('.c-service-packages__input')
      productsInput.forEach((input) => {
        input.classList.add('a/b-detail-view')
      })
    })
    window.VWO = window.VWO || []
    window.VWO.push(['activate', false, [59], true])
  }

  initProductPriceExperiment() {
    window.addEventListener('aboalarm:productPriceExperiment', () => {
      const products = document.querySelectorAll('[data-context="a/b-product"]')
      products.forEach((product) => {
        const productIcon = product.querySelector('[data-context="a/b-product-price__icon"]')
        const productTitle = product.querySelector('[data-context="a/b-product-price__title"]')
        const productPrice = product.querySelector('[data-context="a/b-product-price__price"]')
        const productVat = product.querySelector('[data-context="a/b-product-price__vat"]')

        productIcon.style.cssText = `
          width: 48px;
          height: 38px;
        `

        // Adjusting title design
        product.insertAdjacentElement('afterbegin', productIcon)
        productTitle.style.cssText = `
        background: none;
        border: 0;
        color: var(--color_text);
        font-size: 1rem;
        font-weight: 700;
        padding: 0;
        `

        // Moving price & title
        const productTitlePriceWrapper = document.createElement('div')
        productTitlePriceWrapper.classList.add('a/b-product-title-price-wrapper')
        productIcon.insertAdjacentElement('afterend', productTitlePriceWrapper)
        productTitlePriceWrapper.appendChild(productTitle)
        productTitlePriceWrapper.appendChild(productPrice)

        productPrice.style.cssText = `
          align-items: baseline;
          color: var(--color__secondary);
          flex-direction: row;
          font-size: .625rem;
          text-align: left;
        `

        // Adjusting price prefix & vat
        productPrice.querySelector('.u-font-size\\:small-regular').style.cssText = `font-size: .625rem`
        productVat.style.cssText = `margin-left: .25rem;`
      })
    })
    window.VWO = window.VWO || []
    window.VWO.push(['activate', false, [57], true])
  }

  introduceKeyElements() {
    this.form = this.el.querySelector('form')
    this.backButton = this.el.querySelector('[data-context="back-button"]')
    this.products = this.form.querySelectorAll('[name="checkout_form[standard_product_id]"]')
    this.selectedPackageElem = this.form.querySelector('[name="checkout_form[standard_product_id]"]:checked')
    this.additionalProducts = this.form.querySelectorAll('[name^="checkout_form[addon_product_ids]"]')
    this.checkoutPrice = this.el.querySelector('[data-context="checkout-price"]')
  }

  introduceProps() {
    this.selectedPackage = this.selectedPackageElem['dataset']['productName']
    this.amplitudeData = window['ANALYTICS_DATA']
      ? {
          slug: ANALYTICS_DATA.vendor_slug,
          contractType: ANALYTICS_DATA.category,
          brandName: ANALYTICS_DATA.vendor_brand,
        }
      : {}
    this.flowType = this.el.dataset.flowType
    this.apiUrl = this.el.dataset.host
    this.vendorId = this.el.dataset.vendorId
    this.cart = []
  }

  attachEventListeners() {
    this.backButton.addEventListener('click', this.backButtonCallback.bind(this))
    this.products.forEach((product) => {
      product.addEventListener('change', this.setSelectedPackage.bind(this, product))
    })
    if (this.additionalProducts) {
      this.additionalProducts.forEach((additionalProduct) => {
        additionalProduct.addEventListener('change', () => {
          additionalProduct.checked
            ? this.addAdditionalPackage(additionalProduct)
            : this.removeAdditionalPackage(additionalProduct)
        })
      })
    }
    this.selectedPackageElem.dispatchEvent(new Event('change', { bubbles: true }))
    this.form.addEventListener('submit', this.submitListener.bind(this))
  }

  submitListener(e) {
    if ('submitting' in this.form.dataset) return e.preventDefault()

    VWO.triggerEvent(243)
    this.form.dataset.submit = 'submitting'
    Voucher.clearDataFromCookie()
  }

  backButtonCallback() {
    GoogleTagManager.pushDataLayerEvent('gotBackToCancellationFormPage')
    Amplitude.logEvent('CS Shipping Page Back Button')
    history.back()
  }

  setSelectedPackage(product) {
    const cartWithoutPackages = this.cart.filter((obj) => obj.name !== 'checkout_form[standard_product_id]')
    this.cart = cartWithoutPackages
    this.selectedPackage = product.dataset.productName
    this.cart.push(this.convertNodeToObject(product))
    this.trackDeliveryMethodSelection()
    this.calculateTotalPrice()
  }

  addAdditionalPackage(additionalProduct) {
    if (additionalProduct.dataset.productName === 'termination_security_service') VWO.triggerEvent(246)
    this.cart.push(this.convertNodeToObject(additionalProduct))
    Amplitude.logEvent('CS Shipping Page Extra Shipping Method Selected', {
      ...this.amplitudeData,
      extraShippingOptionName: additionalProduct.dataset.title,
    })
    this.calculateTotalPrice()
  }

  removeAdditionalPackage(additionalProduct) {
    const cartWithoutAdditionalProduct = this.cart.filter((obj) => obj.productId !== additionalProduct.value)
    this.cart = cartWithoutAdditionalProduct
    this.calculateTotalPrice()
  }

  convertNodeToObject(domEl) {
    if (!domEl || !domEl.dataset) return {}

    return Object.assign({}, domEl.dataset, {
      productId: domEl.value,
      name: domEl.name,
    })
  }

  listenVoucherAmountChangedEvent() {
    document.addEventListener('aboalarm:vouchers:amountChanged', (res) => {
      this.voucherDiscount = res.detail.amount
      this.voucherId = res.detail.voucherId
      this.calculateTotalPrice()
    })
  }

  fetchPreSummary(productIds) {
    const params = new URLSearchParams()
    productIds.forEach((productId) => params.append('product_ids[]', productId))
    params.append('voucher_id', this.voucherId)
    params.append('vendor_id', this.vendorId)

    return fetch(`${this.apiUrl}/v2/orders/pre_summary?${params}`)
      .then((response) => response.json())
      .catch((error) => {
        Rollbar.error(
          `There was a problem fetching the pre summary for product_ids: ${productIds}, voucher_id: ${this.voucherId}`,
          error
        )
        return null
      })
  }

  calculateTotalPrice() {
    const cartTotalPrice = this.cart
      .map((obj) => parseInt(obj.price, 10))
      .reduce((accumulator, current) => accumulator + current, 0)
    const formattedCartTotalPrice = I18n.numberToCurrency(cartTotalPrice / 100)
    const priceBeforeVoucher = this.el.querySelector('[data-context="price-before-voucher"]')

    if (this.voucherDiscount > 0) {
      const productIds = this.cart.map((cartItem) => cartItem.productId)
      this.fetchPreSummary(productIds).then((preSummary) => {
        if (preSummary === null) {
          this.checkoutPrice.innerText = formattedCartTotalPrice
          return this.setCTAtext('paid')
        }

        ShowElement(priceBeforeVoucher)
        priceBeforeVoucher.classList.add('u-text-decoration:line-through')
        this.checkoutPrice.classList.add('u-color:primary')
        priceBeforeVoucher.innerText = formattedCartTotalPrice
        this.checkoutPrice.innerText = I18n.numberToCurrency(preSummary.total_amount / 100)
        this.setCTAtext(preSummary.total_amount > 0 ? 'paid' : 'free')
      })
    } else {
      HideElement(priceBeforeVoucher)
      priceBeforeVoucher.classList.remove('u-text-decoration:line-through')
      this.checkoutPrice.classList.remove('u-color:primary')
      this.checkoutPrice.innerText = formattedCartTotalPrice
      this.setCTAtext('paid')
    }
  }

  setCTAtext(paymentType) {
    this.el.querySelector('[data-context="submit-button"]').textContent = I18n.t(
      `frontend.checkout_form.${this.flowType}.submit_button.${paymentType}`
    )
  }

  trackDeleteMyDataConsent() {
    if (!sessionStorageAvailable()) return

    if (sessionStorageGetItem('data_deletion') === 'consent_given') {
      const userEmailEl = this.el.querySelector('[data-user-email]')
      if (userEmailEl) Amplitude.setUserProperties({ email: userEmailEl.dataset.userEmail })
    }
  }

  trackCheckoutPageInitialize() {
    VWO.triggerEvent(242)
    if (!ANALYTICS_DATA) return

    GoogleTagManager.pushDataLayerEvent('shippingPageInitialized', {
      cancellationType: 'external',
      cancellationCategoryId: ANALYTICS_DATA.category_id,
      cancellationCategory: ANALYTICS_DATA.category,
      cancellationVendor: ANALYTICS_DATA.vendor_name,
      cancellationBrand: ANALYTICS_DATA.vendor_brand,
      availableProducts: ANALYTICS_DATA.available_products,
      vendorId: ANALYTICS_DATA.vendor_id,
    })
    Amplitude.logEvent('CS Shipping Page Pageview', {
      ...this.amplitudeData,
      returningCustomer: ANALYTICS_DATA.returning_customer,
      discountApplied: HasDiscountBeenApplied(),
    })
  }

  initializeMoneyBackTracking() {
    const moneyBackAccordion = this.el.querySelector('[data-context="amplitude-tracking-money-back"]')
    if (!moneyBackAccordion) return

    moneyBackAccordion.addEventListener(
      'click',
      () => {
        Amplitude.logEvent('CS Shipping Page Money-Back Service Toggle Opened', this.amplitudeData)
      },
      { once: true }
    )

    this.el.querySelector('[data-context="money-back-checkbox"]').addEventListener('change', (e) => {
      if (e.target.checked) {
        Amplitude.logEvent('CS Shipping Page Extra Shipping Method Selected', {
          ...this.amplitudeData,
          extraShippingOptionName: e.target.dataset.title,
        })
      }
    })
  }

  initSocialProofExperiment() {
    window.addEventListener('aboalarm:socialProofExperiment', () => {
      if (this.el.querySelector('[data-context="a/b-social-proof"]')) {
        this.el.querySelector('[data-context="a/b-social-proof"]').classList.remove('u-display:none')
        this.el
          .querySelector('[data-context="a/b-social-proof-wrapper-element"]')
          .classList.add('c-service-packages--recommended')
      }
    })
    window.VWO = window.VWO || []
    window.VWO.push(['activate', false, [47], true])
  }

  trackDeliveryMethodSelection() {
    Amplitude.logEvent('CS Shipping Page Delivery Method Selected', {
      ...this.amplitudeData,
      deliveryMethodClicked: this.selectedPackage,
    })
  }
}

/**
 * ⚠️ Shared module that's in use in both Volders and Aboalarm.
 */

import { InputVincent } from '../components/input_vincent'
import { InputAboalarm } from '../aboalarm/components/input_aboalarm.js'
import { Amplitude } from '../integrations/amplitude'
import { IsValidEmailAddress, IsValidDate, IsValidYear, InitInputMaskFor } from '../helpers/utilities'

export class ProfileForm {
  constructor() {
    this.el = document.querySelector('[data-context=profile-form]')
    if (!this.el) return

    this.currentMarket = document.body.dataset.currentMarket
    this.submitEl = this.el.querySelector('button[type="submit"]')
    this.submitElText = this.submitEl.querySelector('.btn__text')

    this.setMarketVariables()
    this.setButtonMode('disabled')
    this.submitAttempted = false
    this.submitting = false
    this.captureSubmit()
    this.captureSuccess()
    this.captureError()
    this.initFields()
    this.sendProfilePageViewForAmplitude()
  }

  setMarketVariables() {
    if (this.currentMarket === 'aboalarm') {
      return (this.variables = {
        firstName: 'first-name',
        lastName: 'last-name',
        address: 'address',
        zipcode: 'zipcode',
        city: 'city',
        countryId: 'country-id',
        phone: 'phone',
        dateOfBirth: 'date-of-birth',
        email: 'email',
        btnSuccessMessage: 'btn--success-message',
        hasLoadingState: 'btn--has-loading-state',
      })
    }

    this.variables = {
      firstName: 'firstName',
      lastName: 'lastName',
      address: 'address',
      zipcode: 'zipcode',
      city: 'city',
      countryId: 'countryId',
      phone: 'phone',
      dateOfBirth: 'dateOfBirth',
      email: 'email',
      btnSuccessMessage: 'btn--successMessage',
      hasLoadingState: 'btn--hasLoadingState',
    }
  }

  sendProfilePageViewForAmplitude() {
    Amplitude.logEvent('MAX profile pageview')
  }

  sendProfileUpdatedForAmplitude() {
    Amplitude.logEvent('MAX profile data updated')
  }

  setButtonMode(mode) {
    this.submitEl.classList.remove('btn--success')
    this.submitEl.classList.remove(this.variables.hasLoadingState)
    this.submitEl.classList.remove(this.variables.btnSuccessMessage)
    this.submitEl.removeAttribute('disabled')
    clearTimeout(this.buttonTimeout)

    if (!this.originalButtonText) {
      this.originalButtonText = this.submitElText.innerText
    }
    this.submitElText.innerText = this.originalButtonText

    if (mode == 'disabled') {
      this.submitEl.setAttribute('disabled', true)
    }
    if (mode == 'ready') {
      this.submitEl.classList.add('btn--success')
    }
    if (mode == 'spinning') {
      this.submitEl.setAttribute('disabled', true)
      this.submitEl.classList.add(this.variables.hasLoadingState)
    }
    if (mode == 'success') {
      this.submitEl.classList.add(this.variables.hasLoadingState)
      this.submitElText.innerText = I18n.t('frontend.profile_form.saved')
    }
  }

  captureSuccess() {
    this.el.addEventListener('ajax:success', () => {
      this.setButtonMode('success')
      this.reEvaluateInitialValues()
      this.sendProfileUpdatedForAmplitude()
      this.buttonTimeout = setTimeout(() => {
        this.setButtonMode('disabled')
      }, 3000)
    })
  }

  captureError() {
    this.el.addEventListener('ajax:error', (event) => {
      const [_data, _status, xhr] = event.detail
      const responseJson = JSON.parse(xhr.responseText)
      const emailErrorMessage = responseJson.email && responseJson.email[0]

      if (emailErrorMessage) {
        this.fields[this.variables.email].showError(`${this.fields[this.variables.email].label()} ${emailErrorMessage}`)

        this.buttonTimeout = setTimeout(() => {
          this.setButtonMode('disabled')
        }, 500)
      }
    })
  }

  reEvaluateInitialValues() {
    Object.keys(this.initialValues).forEach((fieldName) => {
      this.initialValues[fieldName] = this.fields[fieldName].value()
    })
  }

  captureSubmit() {
    this.el.addEventListener('ajax:before', (event) => {
      this.submitAttempted = true
      let isValid = this.validateForm()

      if (isValid) {
        this.submitting = true
        this.setButtonMode('spinning')
      } else {
        event.preventDefault()
      }
    })
  }

  initFields() {
    this.fields = {}
    this.initialValues = {}

    this.initField(this.variables.firstName, () => {
      this.validatePresence(this.variables.firstName)
      this.updateFormStatus()
    })
    this.initField(this.variables.lastName, () => {
      this.validatePresence(this.variables.lastName)
      this.updateFormStatus()
    })
    this.initField(this.variables.address, () => {
      this.validatePresence(this.variables.address)
      this.updateFormStatus()
    })
    this.initField(this.variables.zipcode, () => {
      this.validatePresence(this.variables.zipcode)
      this.updateFormStatus()
    })
    this.initField(this.variables.city, () => {
      this.validatePresence(this.variables.city)
      this.updateFormStatus()
    })
    this.initField(this.variables.countryId, () => {
      this.validatePresence(this.variables.countryId)
      this.updateFormStatus()
    })
    this.initField(this.variables.phone, () => {
      this.validatePresence(this.variables.phone)
      this.updateFormStatus()
    })
    this.initField(this.variables.dateOfBirth, () => {
      if (this.fields[this.variables.dateOfBirth].hasError()) {
        this.validateDateOfBirth()
      } else {
        this.fields[this.variables.dateOfBirth].hideValid()
      }
      this.updateFormStatus()
    })
    this.initField(this.variables.email, () => {
      this.validatePresence(this.variables.email)
      this.validateEmailFormat()
      this.updateFormStatus()
    })

    InitInputMaskFor(this.fields[this.variables.dateOfBirth][this.currentMarket === 'aboalarm' ? 'inputEl' : 'input'])
  }

  initField(name, callback) {
    this.fields[name] =
      this.currentMarket === 'aboalarm'
        ? new InputAboalarm(`${name}`, callback)
        : new InputVincent(`ProfileForm-${name}`, callback)

    this.initialValues[name] = this.fields[name].value()
  }

  updateFormStatus() {
    let dirty = this.detectChangedFields()
    if (dirty) {
      this.setButtonMode('ready')
    } else {
      this.setButtonMode('disabled')
    }
  }

  detectChangedFields() {
    return Object.keys(this.initialValues).some(
      (fieldName) => this.initialValues[fieldName] !== this.fields[fieldName].value()
    )
  }

  validateForm() {
    let isValid =
      this.validatePresence(this.variables.firstName) &&
      this.validatePresence(this.variables.lastName) &&
      this.validateDateOfBirth() &&
      this.validatePresence(this.variables.phone) &&
      this.validatePresence(this.variables.address) &&
      this.validatePresence(this.variables.zipcode) &&
      this.validatePresence(this.variables.city) &&
      this.validatePresence(this.variables.countryId) &&
      this.validatePresence(this.variables.email) &&
      this.validateEmailFormat()

    return isValid
  }

  validateDateOfBirth() {
    let valid = true
    const dateField = this.fields[this.variables.dateOfBirth]

    if (IsValidDate(dateField.value()) && IsValidYear(dateField.value())) {
      dateField.hideError()
      dateField.showValid()
    } else {
      if (!this.submitAttempted) {
        dateField.hideValid()
      } else {
        dateField.showError(I18n.t('frontend.profile_form.errors.presence_date_of_birth'))
      }

      valid = false
    }

    return valid
  }

  validatePresence(inputName) {
    let valid = true
    let input = this.fields[inputName]

    if (input.value().trim().length) {
      input.hideError()
      input.showValid()
    } else {
      if (!this.submitAttempted) {
        input.hideValid()
      } else {
        input.showError(I18n.t('frontend.profile_form.errors.presence', { field_name: input.label() }))
      }

      valid = false
    }
    return valid
  }

  validateEmailFormat() {
    let input = this.fields[this.variables.email]

    if (IsValidEmailAddress(input.value())) {
      input.hideError()
      input.showValid()

      return true
    }

    if (!this.submitAttempted) {
      input.hideValid()

      return true
    }

    input.showError(I18n.t('frontend.profile_form.errors.invalid', { field_name: input.label() }))

    return false
  }
}

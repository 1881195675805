/**
 * ⚠️ Shared module that's in use in both Volders and Aboalarm.
 * ⚠️ This module is used in both the login and password reset forms.
 */

import { InputVincent } from '../components/input_vincent'
import { InputAboalarm } from '../aboalarm/components/input_aboalarm.js'
import { Amplitude } from '../integrations/amplitude'

export class LoginForm {
  constructor() {
    this.loginForm = document.querySelector('[data-context="login-form"]')
    this.loginResetForm = document.querySelector('[data-context="login-reset-form"]')
    this.fields = {}
    this.currentMarket = document.body.dataset.currentMarket
    if (!this.loginForm && !this.loginResetForm) return

    if (this.loginResetForm) return this.initFieldsForPasswordReset()

    this.initFieldsForLogin()
    this.logLoginError()
  }

  initFieldsForLogin() {
    this.initField('email', () => undefined, 'UserSessionForm')
    this.initField('password', () => undefined, 'UserSessionForm')
  }

  initFieldsForPasswordReset() {
    this.initField('password', () => undefined, 'CreatePasswordForm')
    this.initField('passwordConfirmation', () => undefined, 'CreatePasswordForm')
  }

  initField(name, callback, formName) {
    this.fields[name] =
      this.currentMarket === 'aboalarm'
        ? new InputAboalarm(`${name}`, callback)
        : new InputVincent(`${formName}-${name}`, callback)
  }

  logLoginError() {
    const loginErrorContext = this.loginForm.querySelector('[data-context="login-error"]')

    if (loginErrorContext) {
      Amplitude.logEvent('MAX account login error', {
        validationErrorMessage: loginErrorContext.dataset.loginErrorMessages || loginErrorContext.textContent,
        validationErrorLabel: loginErrorContext.dataset.loginErrorLabels,
      })
    }
  }
}

import { Amplitude } from '../integrations/amplitude'

export class UnifiedSignupCreated {
  constructor() {
    this.el = document.querySelector('[data-context=unified-signup-created]')
    if (!this.el) return

    Amplitude.logEvent('MAX account requested')
  }
}

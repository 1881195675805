import { isMobile } from '../helpers/utilities'

export class Amplitude {
  constructor() {
    this.setScreenResolution()
  }

  setScreenResolution() {
    this.isMobile = isMobile()
  }

  amplitudeEventResolver(cb) {
    const amplitudeApiKey = amplitude.getInstance().options && amplitude.getInstance().options.apiKey
    if (!amplitudeApiKey) {
      window.addEventListener('volders:amplitudeConsentIsGiven', () => {
        cb()
      })
    } else {
      cb()
    }
  }

  static logEvent(name, props) {
    if (name) {
      const newAmpInstance = new Amplitude()
      const properties = props ? props : {}
      const deviceCategory = newAmpInstance.isMobile ? 'mobile' : 'desktop'
      const propertiesWithDevice = { ...properties, deviceCategory }
      newAmpInstance.amplitudeEventResolver(() => {
        amplitude.getInstance().logEvent(name, propertiesWithDevice)
      })
    }
  }

  static logRevenue(productId, quantity, price, props) {
    if (!!quantity && !!price) {
      new Amplitude().amplitudeEventResolver(() => {
        let revenue = new amplitude.Revenue()
        revenue.setProductId(productId).setPrice(price).setQuantity(quantity).setEventProperties(props)
        amplitude.getInstance().logRevenueV2(revenue)
      })
    }
  }

  static setUserId(userId) {
    if (userId) {
      new Amplitude().amplitudeEventResolver(() => {
        amplitude.getInstance().setUserId(userId)
      })
    }
  }

  static setUserProperties(properties) {
    if (properties) {
      new Amplitude().amplitudeEventResolver(() => {
        amplitude.getInstance().setUserProperties(properties)
      })
    }
  }
}

import { VendorSearch } from '../../components/vendor_search'

export class VendorPage {
  constructor() {
    this.el = document.querySelector('[data-context="vendor-page"]')
    if (!this.el) return

    this.init()
    this.copyAddress()
  }

  init() {
    new VendorSearch()
  }

  copyAddress() {
    const btn = document.querySelector('[data-context="copy-address"]')
    if (!btn) return

    btn.addEventListener('click', () => {
      navigator.clipboard.writeText(document.querySelector('[data-context="copy-address-target"]').innerText)
      btn.innerText = 'Adresse kopiert'
    })
  }
}

import { VendorSearch } from '../../components/vendor_search'
import { createLinkItemForDropdown } from '../helpers/element-modifiers'
import { Amplitude } from '../../integrations/amplitude'

export class CategoryLandingPage {
  constructor() {
    this.el = document.querySelector('[data-context="category-landing-page"]')
    if (!this.el) return

    this.init()
    this.pushTrackingEvents()
  }

  init() {
    new VendorSearch({ createLinkItem: createLinkItemForDropdown, extraLinkItemBuilder: this.extraLinkItemBuilder })
  }

  extraLinkItemBuilder(recentInputVal) {
    return this.createLinkItem(
      {
        slug: '',
        extraArgs: { anbieter: recentInputVal },
        brand: 'Kündigungsschreiben Mustervorlage verwenden',
      },
      {
        newCancellationUrl: this.urlPrefix,
        searchResultSlugSuffix: this.slugSuffix,
      }
    )
  }

  pushTrackingEvents() {
    Amplitude.logEvent('CS Landing Page Pageview', {
      slug: new URL(document.location).pathname.slice(1),
      landingPageType: 'category',
      cancellationType: 'regular',
    })
  }
}

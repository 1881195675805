import { Debounce } from '../helpers/utilities'

export class InputVincent {
  constructor(name, changeCallback = null) {
    this.el = document.querySelector(`[data-name=${name}]`)
    if (!this.el) return

    this.previousValue = null
    this.shown = this.el.hasAttribute('data-shown')
    this.required = this.el.hasAttribute('data-required')
    this.input = this.el.querySelector('input, textarea, select')
    this.labelText = this.el.dataset.label
    this.forceError = this.el.hasAttribute('data-force-error')
    this.error_el = this.el.querySelector('[data-role=error]')
    this.tooltip = this.el.querySelector('[data-context=tooltip-text]')
    this.toggleMaskBtnEl = this.el.querySelector(`[data-role='toggle']`)

    if (this.input) {
      let throttleTimer = 0
      const debounceThreshold = 50
      const debounceUpdateCallback = (e) =>
        Debounce(this.debounceUpdate.apply(this, [changeCallback, e]), debounceThreshold)

      this.input.addEventListener('focus', () => {
        this.focus()
      })
      this.input.addEventListener('blur', () => {
        this.blur()
      })

      if (changeCallback) {
        ;['input', 'change', 'blur'].forEach((eventName) => {
          this.input.addEventListener(eventName, (e) => {
            if (throttleTimer + debounceThreshold > Date.now()) return

            throttleTimer = Date.now()
            debounceUpdateCallback(e)
          })
        })
      }

      window.addEventListener('pageshow', () => {
        this.blur()

        if (changeCallback) {
          this.debounceUpdate(changeCallback)
        }
      })
    }

    if (this.forceError) {
      this.el.setAttribute('data-state', 'invalid')
      this.el.setAttribute('data-error', '')
      this.error_el.innerHTML = this.el.getAttribute('data-force-error')
    }

    if (this.toggleMaskBtnEl) {
      this.isInputMasked = true
      this.revealString = this.toggleMaskBtnEl.dataset.reveal
      this.maskString = this.toggleMaskBtnEl.dataset.mask
      this.listenToggleInputMask()
    }

    return this
  }

  debounceUpdate(changeCallback, event) {
    if (this._isBlank(this.previousValue) && this._isBlank(this.value())) {
      return
    }

    if (this.previousValue !== this.value()) {
      changeCallback(event)
      this.previousValue = this.value()
    }
  }

  focus() {
    this.el.setAttribute('data-focussed', '')
  }

  blur() {
    this.el.removeAttribute('data-focussed')
  }

  hasError(expectedMessage = null) {
    if (!this.el.hasAttribute('data-error')) return false

    return expectedMessage == null || expectedMessage == this.errorMessage()
  }

  showError(error = null) {
    this.el.setAttribute('data-state', 'invalid')
    if (error) {
      this.el.setAttribute('data-error', '')
      if (this.error_el) {
        if (error != this.error_el.innerHTML) {
          this.el.removeAttribute('data-force-error')
          this.forceError = false
        }
        this.error_el.innerHTML = error
      }
    }
  }

  hideError() {
    if (this.forceError) return
    this.el.removeAttribute('data-state')
    this.el.removeAttribute('data-error')
  }

  showValid() {
    if (this.forceError) return
    this.el.setAttribute('data-state', 'valid')
  }

  hideValid() {
    if (this.forceError) return

    if (this.el.dataset.state == 'valid') this.el.removeAttribute('data-state')
  }

  value() {
    return this.input.value
  }

  valueRepresentation() {
    const inputEl = this.input
    if (inputEl.tagName === 'SELECT') return inputEl.options[inputEl.selectedIndex].text
    return this.value()
  }

  label() {
    return this.labelText
  }

  name() {
    return this.input.name
  }

  setLabel(label = '') {
    this.el.querySelector('[data-role=label]').innerHTML = label
  }

  show() {
    this.el.removeAttribute('data-hidden')
    this.shown = true
  }

  hide() {
    this.el.setAttribute('data-hidden', '')
    this.shown = false
  }

  isInvalid() {
    return this.el.dataset.state == 'invalid'
  }

  errorMessage() {
    return this.error_el ? this.error_el.innerHTML : null
  }

  _isBlank(str) {
    return !str || /^\s*$/.test(str)
  }

  setTooltip(text) {
    if (text) {
      this.tooltip.innerText = text
      this.el.setAttribute('data-with-tooltip', '')
    } else {
      this.tooltip.innerText = ''
      this.el.removeAttribute('data-with-tooltip')
    }
  }

  listenToggleInputMask() {
    this.toggleMaskBtnEl.addEventListener('click', (e) => {
      e.stopPropagation()
      this.toggleInputMask()
    })
  }

  toggleInputMask() {
    this.isInputMasked = !this.isInputMasked
    const inputVisibility = this.isInputMasked ? 'off' : 'on'
    const inputType = this.isInputMasked ? 'password' : 'text'
    const altText = this.isInputMasked ? this.revealString : this.maskString

    this.el.setAttribute('data-masked-input-visible', inputVisibility)
    this.input.setAttribute('type', inputType)
    this.toggleMaskBtnEl.textContent = altText
  }
}

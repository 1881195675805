import SignaturePad from 'signature_pad'
import { Amplitude } from '../../integrations/amplitude'
import { GoogleTagManager } from '../../integrations/google_tag_manager'
import { formatBytes } from '../../helpers/utilities'

export class SignatureUploader {
  constructor(amplitudeData) {
    this.el = document.querySelector('[data-context="signature-section"]')
    if (!this.el) {
      return
    }

    this.finalSignatureField = this.el.querySelector('[data-context="final-signature-field"]')
    this.userSet = false
    this.imagePreview = this.el.querySelector('[data-context="signature-preview"]')
    this.letterPreviewElement = document.querySelector('[data-context="letter-signature"]')
    this.letterImagePreview = this.letterPreviewElement.querySelector('[data-context="letter-signature-preview"]')
    this.padMaxWidth = 492
    this.padWidthHeightRatio = 0.35
    this.padWidth = this.padMaxWidth
    this.signatureData = null
    this.amplitudeData = amplitudeData
    this.fileReader = new FileReader()

    this.initDrawnSignature()
    this.initUploadedSignature()
  }

  initUploadedSignature() {
    this.uploadedSignatureField = this.el.querySelector('[data-context="uploaded-signature-field"]')

    this.fileReader.onload = (e) => {
      this.fillSignatureData({ url: e.target.result, type: 'upload' })
      this.emitSetSignatureCallback()
    }

    if (!this.uploadedSignatureField) return

    this.uploadedSignatureField.addEventListener('focus', () => {
      this.uploadedSignatureField.blur()
    })

    this.uploadedSignatureField.addEventListener('change', () => {
      const maxFileSize = 524288
      let file = this.uploadedSignatureField.files[0]
      if (!file) return

      if (file.size <= maxFileSize) {
        this.fileReader.readAsDataURL(file)
      } else {
        this.uploadedSignatureField.value = ''
        this.finalSignatureField.value = ''
        alert(I18n.t('frontend.cancellation_form.errors.attachment_upload_size', { size: formatBytes(maxFileSize) }))

        Amplitude.logEvent('CS Form Page Signature Upload Error')
      }
    })
  }

  initDrawnSignature() {
    this.drawnSignatureField = this.el.querySelector('input[data-context="drawn-signature-field"]')
    this.modal = this.el.querySelector('div[data-context="signature-modal"]')
    this.canvas = this.modal.querySelector('canvas[data-context="signature-canvas"]')
    this.dummyCanvas = this.modal.querySelector('canvas[data-context="signature-dummy-canvas"]')

    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(20, 83, 148)',
    })

    this.el.querySelectorAll('[data-context="open-signature-pad"]').forEach((el) => {
      el.addEventListener('click', () => {
        this.showModal()
      })
    })

    this.el.querySelector('[data-context="signature-preview"]').addEventListener('click', () => {
      this.showModal()
    })

    this.modal.querySelector('[data-context="close-modal"]').addEventListener('click', () => {
      this.hideModal()
    })

    this.modal.querySelector('[data-context="signature-pad-clear"]').addEventListener('click', () => {
      this.signaturePad.clear()
      this.drawnSignatureField.removeAttribute('value')
    })

    this.modal.querySelector('[data-context="signature-pad-save"]').addEventListener('click', () => {
      this.hideModal()
      if (this.signaturePad.isEmpty()) {
        return
      }

      let url = this.getSignatureUrl(this.canvas, this.dummyCanvas)
      this.fillSignatureData({ url: url, type: 'drawn' })
      this.emitSetSignatureCallback()
    })

    window.addEventListener('resize', () => {
      this.updateCanvasSize()
    })
    this.updateCanvasSize()
  }

  showModal() {
    this.el.setAttribute('data-modal-shown', '')
  }

  hideModal() {
    this.el.removeAttribute('data-modal-shown')
  }

  emitSetSignatureCallback() {
    this.el.dispatchEvent(new CustomEvent('aboalarm:userSetSignatureCallback'))
  }

  updateWarning() {
    const infoElement = this.el.querySelector('[data-context="signature-info"]')
    const applyErrorClass = !this.userSet ? 'add' : 'remove'
    const applyHighlightClass = this.userSet ? 'add' : 'remove'
    infoElement.classList[applyHighlightClass]('c-box--blue-light', 'c-box--borderless')
    infoElement.classList[applyErrorClass]('c-form-element__feedback--is-invalid', 'c-box--border-error')
  }

  getValidStatus() {
    return this.userSet
  }

  updateCanvasSize() {
    let windowWidth = document.documentElement.clientWidth
    let width = windowWidth > this.padMaxWidth ? this.padMaxWidth : windowWidth

    if (width == this.padWidth) {
      return
    }

    let resizeFactor = width / this.padWidth

    let resizedData = this.resizeData(this.signaturePad.toData(), resizeFactor)

    this.canvas.width = width
    this.canvas.height = width * this.padWidthHeightRatio

    this.signaturePad.clear()
    this.signaturePad.fromData(resizedData)

    this.padWidth = width
  }

  resizeData(data, resizeFactor) {
    let resizedData = data.slice(0)

    for (var i = 0; i < resizedData.length; i++) {
      let path = resizedData[i]
      for (var j = 0; j < path.points.length; j++) {
        let point = path.points[j]
        point.x *= resizeFactor
        point.y *= resizeFactor
      }
    }

    return resizedData
  }

  getSignatureUrl(canvas, dummyCanvas) {
    dummyCanvas.width = canvas.width
    dummyCanvas.height = canvas.height

    let dummyContext = dummyCanvas.getContext('2d')

    dummyContext.fillStyle = '#FFFFFF'
    dummyContext.fillRect(0, 0, canvas.width, canvas.height)
    dummyContext.drawImage(canvas, 0, 0)

    return dummyCanvas.toDataURL()
  }

  fillSignatureData(data) {
    if (!data) return

    if (data.type === 'drawn') this.uploadedSignatureField.value = ''
    this.signatureData = data
    this.finalSignatureField.value = data.url.split(',')[1]
    this.showImagePreview(data.url)
    this._sendAnalyticsSignatureChangedEvent(data.type)
  }

  getSignatureData() {
    return this.signatureData
  }

  showImagePreview(imageSrc) {
    this.imagePreview.src = imageSrc
    this.letterImagePreview.src = imageSrc
    this.el.setAttribute('data-user-set', '')
    this.userSet = true
    this.el.removeAttribute('data-force-actions')
    this.el.removeAttribute('data-show-error')
    this.letterPreviewElement.setAttribute('data-user-set', '')
    this.letterPreviewElement.removeAttribute('data-force-actions')
    this.letterPreviewElement.removeAttribute('data-show-error')
    this.updateWarning()
  }

  _sendAnalyticsSignatureChangedEvent(signatureType) {
    GoogleTagManager.pushDataLayerEvent('signatureChanged', {
      signatureChangeMethod: signatureType,
      signatureRequired: true,
    })
    Amplitude.logEvent('CS Form Page Signature Changed', {
      ...this.amplitudeData,
      newSignatureSource: signatureType,
      signatureRequired: true,
    })
  }
}

import Glide from '@glidejs/glide'

export class GlideSliderAwards {
  constructor() {
    this.el = document.querySelector('[data-context="glide-slider-awards"]')
    if (!this.el) return

    new Glide('.glide', {
      type: 'carousel',
      perView: 4,
      autoplay: 4000,
      breakpoints: {
        1023: {
          perView: 2,
        },
        479: {
          perView: 1,
        },
      },
    }).mount()
  }
}

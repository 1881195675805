import { VendorSearch } from '../../components/vendor_search'
import { createLinkItemForDropdown } from '../helpers/element-modifiers'

export class Homepage {
  constructor() {
    this.el = document.querySelector('[data-context="homepage"]')
    if (!this.el) return

    this.init()
  }

  init() {
    new VendorSearch({ createLinkItem: createLinkItemForDropdown, extraLinkItemBuilder: this.extraLinkItemBuilder })
    this.el.querySelector('[data-context="vendor-search"] input').focus()
  }

  extraLinkItemBuilder(recentInputVal) {
    return this.createLinkItem(
      {
        slug: '',
        extraArgs: { anbieter: recentInputVal },
        brand: 'Kündigungsschreiben Mustervorlage verwenden',
      },
      {
        newCancellationUrl: this.urlPrefix,
        searchResultSlugSuffix: this.slugSuffix,
      }
    )
  }
}

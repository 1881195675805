export class MobileCancellationPage {
  constructor() {
    this.el = document.querySelector('[data-context="mobile-cancellation-page"]')
    if (!this.el) return

    this.init()
  }

  init() {
    window.dispatchEvent(new CustomEvent('aboalarm:openCancellationModal'))
  }
}

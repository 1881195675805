import { InputAboalarm } from '../components/input_aboalarm'
import { validateZipcodeForCountry } from '../../helpers/utilities'
import { ShowElement, HideElement } from '../helpers/element-modifiers'

export class CheckoutConfirmationDownload {
  constructor() {
    this.form = document.querySelector('[data-context="confirmation-download"]')
    this.formPristine = true
    if (!this.form) return

    this.submitButton = this.form.querySelector('[type="submit"]')
    this.zipCodeField = new InputAboalarm(`zipcode`, () => {
      this.validatePostalCode()
    })
    this.countryCode = this.zipCodeField.inputEl.dataset.countryCode
    this.captureSubmit()
  }

  captureSubmit() {
    this.form.addEventListener('submit', (e) => {
      this.submit(e)
    })
  }

  submit(e) {
    this.formPristine = false
    if (!this.validatePostalCode()) {
      e.preventDefault()
    } else {
      this.submitButton.disabled = true
    }
  }

  validatePostalCode() {
    let valid = true
    const field = this.zipCodeField
    const errorFeedbackEl = this.form.querySelector('[data-context="confirmation-download-error"]')
    if (validateZipcodeForCountry(field.value().replace(/\s+/g, ''), this.countryCode)) {
      if (!this.formPristine) {
        field.showValid()
        field.hideError()
        HideElement(errorFeedbackEl)
      }
    } else {
      valid = false
      if (!this.formPristine) {
        field.hideValid()
        field.showError()
        ShowElement(errorFeedbackEl)
      }
    }
    return valid
  }
}

import FingerprintJS from '@fingerprintjs/fingerprintjs'

export class Fingerprint {
  constructor() {
    this.init()
  }

  init() {
    const fpPromise = FingerprintJS.load({
      region: "eu",
      monitoring: false
    })
    ;(async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise
      const result = await fp.get()
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'hash',
        uuid: result.visitorId
      })
    })()
  }
}

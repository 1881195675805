/**
 * Represents the general schema for a given form field in the cancellation form.
 *
 * @typedef {Object} GeneralSchema
 * @property {number} step - The step number of the form field.
 * @property {string} name - The name of the form field.
 * @property {string} type - The type of the form field.
 */
const generalSchema = {
  step: 1,
  name: 'formFieldName',
  type: 'static',
}

/**
 * Represents the form fields for cancellation.
 * @type {Array<GeneralSchema>}
 */
export const CancellationFormFields = [
  {
    ...generalSchema,
    name: 'cancellationTypeDropdown',
  },
  {
    ...generalSchema,
    name: 'contactFirstName',
  },
  {
    ...generalSchema,
    name: 'contactLastName',
  },
  {
    ...generalSchema,
    name: 'contactStreet',
  },
  {
    ...generalSchema,
    name: 'contactStreetNumber',
  },
  {
    ...generalSchema,
    name: 'contactZipcode',
  },
  {
    ...generalSchema,
    name: 'contactCity',
  },
  {
    ...generalSchema,
    name: 'contactCountry',
  },
  {
    ...generalSchema,
    name: 'contactEmail',
  },
  {
    ...generalSchema,
    step: 2,
    name: 'letterSubject',
  },
  {
    ...generalSchema,
    step: 2,
    name: 'letterText',
  },
  {
    step: 2,
    name: 'customVendorName',
    type: 'custom',
  },
  {
    step: 2,
    name: 'customVendorStreet',
    type: 'custom',
  },
  {
    step: 2,
    name: 'customVendorStreetNumber',
    type: 'custom',
  },
  {
    step: 2,
    name: 'customVendorZipcode',
    type: 'custom',
  },
  {
    step: 2,
    name: 'customVendorCity',
    type: 'custom',
  },
  {
    step: 2,
    name: 'customVendorCountry',
    type: 'custom',
  },
  {
    ...generalSchema,
    name: 'movingStreet',
    type: 'moving',
  },
  {
    ...generalSchema,
    name: 'movingStreetNumber',
    type: 'moving',
  },
  {
    ...generalSchema,
    name: 'movingZipcode',
    type: 'moving',
  },
  {
    ...generalSchema,
    name: 'movingCity',
    type: 'moving',
  },
  {
    ...generalSchema,
    name: 'movingCountryId',
    type: 'moving',
  },
  {
    step: 2,
    name: 'movingDate',
    type: 'moving',
  },
  {
    ...generalSchema,
    name: 'deathFirstName',
    type: 'deceased',
  },
  {
    ...generalSchema,
    name: 'deathLastName',
    type: 'deceased',
  },
  {
    ...generalSchema,
    name: 'deathAddressIsDifferent',
    type: 'deceased',
  },
  {
    ...generalSchema,
    name: 'deathStreet',
    type: 'deceased',
  },
  {
    ...generalSchema,
    name: 'deathStreetNumber',
    type: 'deceased',
  },
  {
    ...generalSchema,
    name: 'deathZipcode',
    type: 'deceased',
  },
  {
    ...generalSchema,
    name: 'deathCity',
    type: 'deceased',
  },
  {
    ...generalSchema,
    name: 'deathCountryId',
    type: 'deceased',
  },
  {
    step: 2,
    name: 'deathDate',
    type: 'deceased',
  },
  {
    step: 2,
    name: 'damageClaim',
    type: 'damage',
  },
]

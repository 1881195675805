export const ShowElement = function (domEl) {
  domEl.classList.remove('u-display:none')
}

export const HideElement = function (domEl) {
  domEl.classList.add('u-display:none')
}

export const createLinkItemForDropdown = function (vendor, urlConfig) {
  const frag = document.createDocumentFragment()
  const domLi = document.createElement('li')
  const domA = document.createElement('a')
  const domSpan = document.createElement('span')
  const urlPrefix = urlConfig.newCancellationUrl
  const slugSuffix = urlConfig.searchResultSlugSuffix
  let slug = vendor.slug

  if (slugSuffix) {
    // There's no need to be fully generic which string we're replacing
    // since for now it's only kuendigen.
    slug = slug.replace(/-kuendigen$/, `-${slugSuffix}`)
  }
  let newUrl = new URL(urlPrefix.replace(':slug:', slug))

  if (vendor.extraArgs) {
    for (const arg in vendor.extraArgs) {
      newUrl.searchParams.set(arg, vendor.extraArgs[arg])
    }
  }

  domSpan.textContent = vendor.brand
  domA.href = newUrl.href
  domA.title = vendor.brand
  domA.dataset.brand = vendor.brand
  domA.appendChild(domSpan)

  if (vendor.subject_of_agreements) {
    const domSpanSoA = document.createElement('span')
    domSpanSoA.textContent = vendor.subject_of_agreements[0].name
    domSpanSoA.classList.add('c-search__soa')
    domA.appendChild(domSpanSoA)
  }

  domLi.appendChild(domA)
  frag.appendChild(domLi)

  domA.addEventListener('click', (event) => {
    event.preventDefault()
    this.chosenItemCallback(event.currentTarget)
    this.inputEl.value = event.currentTarget.dataset.brand
    this.hide()
  })

  return frag
}

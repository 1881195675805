import { Debounce } from '../helpers/utilities'

/**
 * vendor search drop down module
 * @module VendorSearch
 */
export class VendorSearch {
  /**
   * VendorSearch module is initiated either solely or with a object payload
   * @param {Object} configObject the required parameter that contains all the fields in an object
   * @param {(function()|null)} configObject.chosenItemCallback An optional callback function that is invoked when the user clicks or submits a link on the dropdown menu. Within the function, you can define customized logic or actions based on the selected item. If specified, overwrites the current 'clickCallback' method
   * @param {(function()|null)} configObject.createLinkItem An optional utility function that is used to dynamically generate HTML elements for creating clickable links shown on the drop down. It can make use of 'vendor' data; taking it in as the first parameter. If specified, overwrites the current 'createLinkItem' method
   * @param {(function()|null)} configObject.extraLinkItemBuilder an optional utility function that helps in generating HTML code for an additional link item to be added to the end of the drop down
   */
  constructor(configObject = {}) {
    this.el = document.querySelector('[data-context="vendor-search"]')
    if (!this.el) {
      return
    }

    this.MAXIMUM_RESULTS = 20
    this.apiUrl = this.el.dataset.host
    this.currentMarket = this.el.dataset.currentMarket
    this.urlPrefix = this.el.dataset.newCancellationUrl
    this.slugSuffix = this.el.dataset.searchResultSlugSuffix
    this.recentInputVal = ''
    this.inputEl = this.el.querySelector('[data-role=input]')
    this.removeValueBtn = this.el.querySelector('[data-role=remove]')
    this.dropdown = this.el.querySelector('[data-role=dropdown]')
    this.linksContainer = this.el.querySelector('[data-role=results]')
    this.dropdownExtended = false
    this.pressedEsc = false
    this.focusIndex = 0
    this.chosenItemCallback = configObject.chosenItemCallback || this.clickCallback
    this.createLinkItem = configObject.createLinkItem || this.createLinkItem
    this.extraLinkItemBuilder = configObject.extraLinkItemBuilder

    this.setInputListenerEvents()
  }

  setInputListenerEvents() {
    ;['keydown', 'focus'].forEach((eventName) => {
      this.inputEl.addEventListener(
        eventName,
        Debounce(() => {
          if (this.pressedEsc) {
            this.pressedEsc = false
            return
          }
          this.inputChangeCb()
        }, 100)
      )
    })

    this.removeValueBtn.addEventListener('click', () => {
      this.el.removeAttribute('data-focused')

      this.inputEl.value = ''
      this.hide()
      this.chosenItemCallback(null)
    })

    document.addEventListener('keydown', (event) => {
      if (!this.dropdownExtended) {
        return
      }
      const key = event.which
      const pressedUpArrow = key === 38
      const pressedDownArrow = key === 40
      if (pressedUpArrow || pressedDownArrow) {
        if (pressedUpArrow) this.focusedElementResolver('substract')
        if (pressedDownArrow) this.focusedElementResolver('add')
        return event.preventDefault()
      }
      switch (key) {
        case 27:
          this.callbackEscapeKey()
          break
        case 13:
          this.callbackEnterKey()
          break
        default:
          this.callbackLetterKey(key)
          break
      }
    })
  }

  clickCallback(item) {
    if (!item) return
    location.href = item.href
  }

  callbackEscapeKey() {
    this.focusedElementResolver('reset')
    this.pressedEsc = true
    this.hide()
  }

  callbackEnterKey() {
    if (this.focusIndex === 0) {
      dataLayer.push({ event: 'submittedEmptySearchWithEnter' })
    } else {
      const domLink = this.linksContainer.querySelector('[data-dropdown-link-focused]')
      this.chosenItemCallback(domLink)
      this.inputEl.value = domLink.dataset.brand
      this.hide()
    }
  }

  callbackLetterKey(key) {
    const isRightorLeftArrow = key === 37 || key === 39
    if (this.focusIndex === 0 || isRightorLeftArrow) {
      return
    }
    this.resetFocus()
    this.inputEl.focus()
  }

  focusedElementResolver(mathType) {
    const links = this.linksContainer.querySelectorAll('a')
    const linksCount = links.length
    const lastSelectedLink = this.linksContainer.querySelector('[data-dropdown-link-focused]')
    switch (mathType) {
      case 'add':
        this.focusIndex = this.focusIndex === linksCount ? 0 : ++this.focusIndex
        break
      case 'substract':
        this.focusIndex = this.focusIndex === 0 ? linksCount : --this.focusIndex
        break
      case 'reset':
        this.focusIndex = 0
        break
    }
    if (lastSelectedLink) {
      lastSelectedLink.removeAttribute('data-dropdown-link-focused')
    }
    if (this.focusIndex === 0) {
      this.inputEl.focus()
    } else {
      if (linksCount) {
        const domToggled = links[this.focusIndex - 1]
        domToggled.setAttribute('data-dropdown-link-focused', true)
      }
      this.inputEl.blur()
    }
    this.analyzeScrollPossibility(links)
  }

  analyzeScrollPossibility(linksDomEl) {
    let newOffsetTop = 0
    if (this.focusIndex <= 5) {
      newOffsetTop = 0
    } else if (this.focusIndex > 5 && this.focusIndex < 12) {
      newOffsetTop = linksDomEl[5].offsetTop + 1
    } else if (this.focusIndex < this.MAXIMUM_RESULTS - 3) {
      newOffsetTop = linksDomEl[11].offsetTop
    } else {
      newOffsetTop = this.dropdown.scrollHeight
    }
    this.dropdown.scrollTop = newOffsetTop
  }

  show() {
    this.el.setAttribute('data-dropdown-extended', true)
    if (!this.dropdownExtended) {
      dataLayer.push({ event: 'dropdownExtended' })
    }
    this.dropdownExtended = true
  }

  resetFocus() {
    this.focusIndex = 0
    this.dropdown.scrollTop = 0
  }

  hide() {
    this.el.removeAttribute('data-dropdown-extended')
    this.dropdownExtended = false
    this.resetFocus()
  }

  isBlank(text) {
    return !text || /^\s*$/.test(text)
  }

  inputChangeCb() {
    this.el.setAttribute('data-focused', true)

    const query = this.inputEl.value
    if (query.length) {
      this.show()
      if (this.recentInputVal !== query) {
        if (this.isBlank(query)) {
          this.updateResults([])
        } else {
          this.fetchResults(query)
        }
      }
    } else {
      this.hide()
      this.chosenItemCallback(null)
    }
  }

  createLinkItem(vendor) {
    const frag = document.createDocumentFragment()
    const domLi = document.createElement('li')
    const domA = document.createElement('a')
    const urlPrefix = location.origin

    domA.classList.add('fz-s')
    domA.textContent = vendor.brand
    domA.href = `${urlPrefix}/${vendor.slug}`
    domA.title = vendor.brand
    domA.dataset.id = vendor.id
    domA.dataset.brand = vendor.brand

    domLi.appendChild(domA)
    frag.appendChild(domLi)

    domA.addEventListener('click', (event) => {
      event.preventDefault()
      this.chosenItemCallback(event.currentTarget)
      this.inputEl.value = event.currentTarget.dataset.brand
      this.hide()
    })

    return frag
  }

  fetchResults(query) {
    fetch(`${this.apiUrl}/v2/vendors/search?query=${encodeURIComponent(query.toLowerCase())}`)
      .then((response) => response.json())
      .then((resp) => {
        this.evaluateResults(resp, query)
      })
      .catch(() => {
        this.updateResults([])
      })
  }

  evaluateResults(fetchResponse, query) {
    const vendors = fetchResponse.vendors
    if (Array.isArray(vendors)) {
      this.updateResults(vendors, query)
    }
  }

  updateResults(vendors, query) {
    this.recentInputVal = query
    this.el.removeAttribute('data-dropdown-capped')

    if (vendors.length === this.MAXIMUM_RESULTS) {
      this.el.setAttribute('data-dropdown-capped', true)
    }

    while (this.linksContainer.firstChild) {
      this.linksContainer.removeChild(this.linksContainer.firstChild)
    }

    for (let i = 0; i < vendors.length; i++) {
      const newLiItem = this.createLinkItem(vendors[i], {
        newCancellationUrl: this.urlPrefix,
        searchResultSlugSuffix: this.slugSuffix,
      })
      this.linksContainer.appendChild(newLiItem)
    }
    this.emitDropdownExpandCallback(vendors.length)
    this.resetFocus()

    if (this.extraLinkItemBuilder)
      this.linksContainer.appendChild(
        this.extraLinkItemBuilder(this.recentInputVal, {
          newCancellationUrl: this.urlPrefix,
          searchResultSlugSuffix: this.slugSuffix,
        })
      )
  }

  emitDropdownExpandCallback(vendorCount) {
    this.el.dispatchEvent(
      new CustomEvent(`${this.currentMarket}:vendorSearchDropdownExpandCallback`, {
        detail: { vendorCount },
      })
    )
  }
}

export class VWO {
  static triggerEvent(eventID) {
    window._vis_opt_queue = window._vis_opt_queue || []
    window._vis_opt_queue.push(function () {
      _vis_opt_goal_conversion(eventID)
    })
  }
  static triggerRevenue(revenue) {
    window._vis_opt_queue = window._vis_opt_queue || []
    window._vis_opt_queue.push(function () {
      _vis_opt_revenue_conversion(revenue)
    })
  }
}

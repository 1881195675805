export class SelectRedirect {
  constructor() {
    this.el = document.querySelector('[data-context="select-redirect-on-change"]')
    if (!this.el) return

    this.init()
  }

  init() {
    this.el.addEventListener('change', (event) => {
      const redirectToPath = event.target[event.target.options.selectedIndex].dataset.redirectTo

      if (!redirectToPath) return

      window.location.href = redirectToPath
    })
  }
}

/**
 * ⚠️ Shared module that's in use in both Volders and Aboalarm.
 */

import { InputVincent } from '../components/input_vincent'
import { InputAboalarm } from '../aboalarm/components/input_aboalarm.js'
import { Amplitude } from '../integrations/amplitude'

export class SignupConfirmationForm {
  constructor() {
    this.form = document.querySelector('[data-context="signup-confirmation-form"]')
    if (!this.form) return

    this.formSubmitted = false
    this.submitButton = this.form.querySelector('button[type="submit"]')
    this.currentMarket = document.body.dataset.currentMarket

    Amplitude.logEvent('MAX create password pageview')
    this.initFields()
    this.captureSubmit()
  }

  initFields() {
    this.fields = {}

    this.initField('password', () => {
      this.validatePasswordFields()
    })
    this.initField('passwordConfirmation', () => {
      this.validatePasswordFields()
    })
    this.initField('firstName', () => {
      this.validatePresence('firstName')
      this.checkButtonState('firstName')
    })
    this.initField('lastName', () => {
      this.validatePresence('lastName')
      this.checkButtonState('lastName')
    })
    this.step = 1
  }

  initField(name, callback) {
    InputAboalarm
    this.fields[name] =
      this.currentMarket === 'aboalarm'
        ? new InputAboalarm(`${name}`, callback)
        : new InputVincent(`SignupConfirmationForm-${name}`, callback)
  }

  validatePasswordFields() {
    let errorMessage = ''
    const passwordField = this.fields['password']
    const passwordConfirmationField = this.fields['passwordConfirmation']

    if (passwordField.value().length < 8) {
      errorMessage = I18n.t('frontend.signup_page.errors.password')
      if (this.formSubmitted) {
        passwordField.showError(errorMessage)
        this.setButtonMode('disabled')
      }
      return false
    }

    passwordField.hideError()

    if (passwordField.value() != passwordConfirmationField.value()) {
      errorMessage = I18n.t('frontend.signup_page.errors.password_mismatch')
      if (this.formSubmitted) {
        passwordConfirmationField.showError(errorMessage)
        this.setButtonMode('disabled')
      }
      return false
    }

    passwordConfirmationField.hideError()
    this.setButtonMode('ready')
    return true
  }

  validatePresence(inputName) {
    let valid = true
    let input = this.fields[inputName]

    if (this.checkFieldValue(input)) {
      input.hideError()
      input.showValid()
    } else {
      valid = false
      if (this.formSubmitted) {
        input.showError(
          I18n.t(`frontend.${this.currentMarket === 'aboalarm' ? 'errors' : 'profile_form.errors'}.presence`, {
            field_name: input.label(),
          })
        )
      }
    }
    return valid
  }

  captureSubmit() {
    this.form.addEventListener('submit', (e) => {
      this.formSubmitted = true

      if (this.step === 1) {
        Amplitude.logEvent('MAX create password CTA clicked')
      } else {
        Amplitude.logEvent('MAX add name CTA clicked')
      }

      if (this.validateForm()) {
        Amplitude.logEvent('MAX account created')

        this.setButtonMode('spinning')
      } else {
        if (this.incrementStep) {
          if (this.step === 1) {
            this.createFauxTransitionForSecondStep()
          } else {
            this.setButtonMode('disabled')
          }
        }
        this.trackValidationErrors()

        e.preventDefault()
      }
    })
  }

  changeSubmitTextToFinishSignup() {
    const submitCopyDomEl =
      this.currentMarket === 'aboalarm' ? this.submitButton : this.submitButton.querySelector('[data-role="text"]')
    submitCopyDomEl.innerText = I18n.t('frontend.signup_page.button')
  }

  checkFieldValue(input) {
    return input.value().trim().length
  }

  createFauxTransitionForSecondStep() {
    this.setButtonMode('spinning')
    const firstStepFields = [this.fields['password'], this.fields['passwordConfirmation']]
    setTimeout(() => {
      this.step = 2
      this.showSecondStep()
      firstStepFields.forEach((firstStepInput) => firstStepInput.hide())
    }, 1000)
  }

  showSecondStep() {
    const headlineDomEl = document.querySelector('[data-context="signup-confirmation-headline"]')
    const sublineDomEl = document.querySelector('[data-context="signup-confirmation-subline"]')
    const newHeadline = I18n.t('frontend.signup_confirmation.form_headline_name')
    const newSubline = I18n.t('frontend.signup_confirmation.form_subline_name')
    const secondStepFields = [this.fields['firstName'], this.fields['lastName']]
    const nameFieldsAreValid = this.validateNameFields()
    const buttonMode = nameFieldsAreValid ? 'ready' : 'disabled'

    headlineDomEl.innerText = newHeadline
    sublineDomEl.innerText = newSubline
    this.changeSubmitTextToFinishSignup()
    secondStepFields.forEach((secondStepField) => this.showField(secondStepField, nameFieldsAreValid))
    this.formSubmitted = false
    this.setButtonMode(buttonMode)
    Amplitude.logEvent('MAX add name pageview')
  }

  showField(field, fieldsAreValid) {
    if (fieldsAreValid) {
      field.showValid()
    } else {
      field.hideError()
    }
    field.show()
  }

  checkButtonState(inputName) {
    const field = this.fields[inputName]

    if (!this.checkFieldValue(field)) {
      field.hideValid()
      return this.setButtonMode('disabled')
    }

    const allFieldsAreFilled =
      inputName === 'firstName' ? this.validatePresence('lastName') : this.validatePresence('firstName')
    const buttonMode = allFieldsAreFilled ? 'ready' : 'disabled'
    this.setButtonMode(buttonMode)
  }

  validateNameFields() {
    return this.validatePresence('firstName') && this.validatePresence('lastName')
  }

  validateForm() {
    this.incrementStep = this.validatePasswordFields()
    return this.step === 2 && this.validateNameFields()
  }

  setButtonMode(mode) {
    this.submitButton.classList.remove('btn--success')
    this.submitButton.classList.remove('btn--hasLoadingState')
    this.submitButton.removeAttribute('disabled')

    if (mode == 'disabled') {
      this.submitButton.setAttribute('disabled', true)
    }
    if (mode == 'ready') {
      this.submitButton.classList.add('btn--success')
    }
    if (mode == 'spinning') {
      this.submitButton.setAttribute('disabled', true)
      this.submitButton.classList.add('btn--hasLoadingState')
    }
  }

  trackValidationErrors() {
    Object.keys(this.fields).forEach((fieldName) => {
      let field = this.fields[fieldName]
      if (field.isInvalid() && field.errorMessage()) {
        Amplitude.logEvent('MAX create password error displayed', {
          ValidationErrorMessage: field.errorMessage(),
          ValidationErrorLabel: fieldName,
        })
      }
    })
  }
}

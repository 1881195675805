export class LoginDialogPopup {
  constructor() {
    this.popup = document.querySelector('[data-context="login-dialog-popup"]')
    this.invokers = document.querySelectorAll('[data-dialog-popup="login"]')
    if (!this.popup || this.invokers.length === 0) return

    this.init()
  }

  init() {
    this.documentEscapeKeyHandler = this.KeyDownHandler.bind(this)
    this.invokers.forEach((invoker) => {
      invoker.addEventListener('click', this.showPopup.bind(this))
    })
    this.popup
      .querySelector('[data-context="close-login-dialog-popup"]')
      .addEventListener('click', this.hidePopup.bind(this))
    this.popup
      .querySelector('[data-context="button-login-dialog-popup"]')
      .addEventListener('click', this.hidePopup.bind(this))
  }

  showPopup() {
    this.popup.setAttribute('aria-hidden', false)
    document.body.setAttribute('data-c-dialog', 'open')
    document.addEventListener('keydown', this.documentEscapeKeyHandler)
  }

  hidePopup() {
    this.popup.setAttribute('aria-hidden', true)
    document.body.setAttribute('data-c-dialog', 'closed')
    document.removeEventListener('keydown', this.documentEscapeKeyHandler)
  }

  KeyDownHandler(e) {
    if (e.which === 27) this.hidePopup()
  }
}

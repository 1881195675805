import { VendorSearch } from '../../components/vendor_search'
import { createLinkItemForDropdown } from '../helpers/element-modifiers'
import { Amplitude } from '../../integrations/amplitude'

export class SpecialCancellationPages {
  constructor() {
    this.el = document.querySelector('[data-context="special-cancellation-types-lp"]')
    if (!this.el) return

    this.init()
    this.createJumpMark()
    this.pushTrackingEvents()
  }

  init() {
    new VendorSearch({ createLinkItem: createLinkItemForDropdown, extraLinkItemBuilder: this.extraLinkItemBuilder })
    this.el.querySelector('[data-context="vendor-search"] input').focus()
  }

  createJumpMark() {
    const button = this.el.querySelector('[data-context="learn-more"]')
    if (!button) return

    const targetURL = button.href.substring(button.href.lastIndexOf('#') + 1)
    const target = document.getElementById(targetURL)

    button.addEventListener('click', () => target.scrollIntoView({ behavior: 'smooth' }))
  }

  extraLinkItemBuilder(recentInputVal) {
    return this.createLinkItem(
      {
        slug: '',
        extraArgs: { anbieter: recentInputVal },
        brand: 'Kündigungsschreiben Mustervorlage verwenden',
      },
      {
        newCancellationUrl: this.urlPrefix,
        searchResultSlugSuffix: this.slugSuffix,
      }
    )
  }

  pushTrackingEvents() {
    Amplitude.logEvent('CS Landing Page Pageview', {
      slug: new URL(document.location).pathname.slice(1),
      landingPageType: 'category',
      cancellationType: this.el.querySelector('[data-context="select-redirect-on-change"] select').value,
    })
  }
}

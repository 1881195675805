import { localStorageAvailable, localStorageGetItem, sessionStorageAvailable } from '../../vendor/storage_utilities'
import { CancellationFormFields } from './cancellation-form-fields'
import { CallAnimationFrame, IsStoredFieldsValid } from '../../helpers/utilities'
import { Amplitude } from '../../integrations/amplitude'
import { VWO } from '../../integrations/vwo'

export class CancellationPage {
  constructor() {
    this.el = document.querySelector('[data-context="cancellation-page"]')
    if (!this.el) return

    this.localStorageAvailable = localStorageAvailable()
    this.amplitudeData = window['ANALYTICS_DATA']
    this.cancellationFormFields = CancellationFormFields
    this.initialCancellationType = this.el.querySelector('[data-context="cancellation-type"] select').value
    this.captureCTAclick()
    this.handlePageShow()
    this.showModalIfLocalStorageHasUserData()
    this.initCtaPositionExperiment()
    this.pushTrackingEvents()
    this.initLandingpageBackgroundExperiment()
  }

  initLandingpageBackgroundExperiment() {
    window.addEventListener('aboalarm:LandingpageBackgroundExperiment', () => {
      this.el.querySelector('[data-context="a/b-landingpage-background"]').classList.add('c-dialog__overlay--opaque')
    })
    window.VWO = window.VWO || []
    window.VWO.push(['activate', false, [51], true])
  }

  initCtaPositionExperiment() {
    window.addEventListener('aboalarm:ctaPositionExperiment', () => {
      let cta = this.el.querySelector('[data-experiment="a/b-cta-position-experiment"]')
      let checkmarks = this.el.querySelector('.c-checkmarks.c-checkmarks--signet')

      if (cta && checkmarks) {
        checkmarks.insertAdjacentElement('afterend', cta)
        cta.classList.add('u-margin-top:2rem')
      }
    })
    window.VWO = window.VWO || []
    window.VWO.push(['activate', false, [55], true])
  }

  captureCTAclick() {
    this.el.querySelectorAll('[data-context="cancellation-cta"]').forEach((el) => {
      el.addEventListener('click', (e) => {
        Amplitude.logEvent('CS Landing Page Main CTA Clicked', {
          ...this.amplitudeData,
          landingPageType: 'vendor',
          cancellationType: this.initialCancellationType,
          buttonType: e.target.dataset.buttonType,
        })
        this.openModal()
      })
    })
  }

  openModal() {
    window.dispatchEvent(new CustomEvent('aboalarm:openCancellationModal'))
    VWO.triggerEvent(248)
  }

  handlePageShow() {
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        window.dispatchEvent(new CustomEvent('aboalarm:closeCancellationModal'))
        this.showModalIfLocalStorageHasUserData()
      }
    })
  }

  showModalIfLocalStorageHasUserData() {
    const storedFields = this.localStorageAvailable ? JSON.parse(localStorageGetItem('cancellation_fields')) : null
    if (!IsStoredFieldsValid(storedFields)) return

    const firstStepRegularFields = this.cancellationFormFields
      .filter((field) => field.step === 1 && field.type === 'static')
      .map((field) => field.name)
    const ignoredFields = ['cancellationTypeDropdown', 'contactCountry'] // cancellation type and country dropdowns are set as default
    for (let fieldName in storedFields.data) {
      if (
        storedFields.data[fieldName] &&
        firstStepRegularFields.includes(fieldName) &&
        !ignoredFields.includes(fieldName)
      ) {
        CallAnimationFrame(this.openModal.bind(this))
        break
      }
    }
  }

  pushTrackingEvents() {
    Amplitude.logEvent('CS Landing Page Pageview', {
      ...this.amplitudeData,
      landingPageType: 'vendor',
      cancellationType: this.initialCancellationType,
    })
  }
}

export class ToggleCheckbox {
  constructor(name, changeCallback = null) {
    this.el = document.querySelector(`[data-name=${name}]`)
    if (!this.el) return

    this.input = this.el.querySelector('input[type="checkbox"]')

    if (changeCallback) {
      this.input.addEventListener('change', (e) => {
        changeCallback(e.target)
      })
    }

    return this
  }

  value() {
    return this.input && this.input.checked
  }
}

import { CallAnimationFrame, Debounce } from '../../helpers/utilities'
import { ShowElement, HideElement } from '../helpers/element-modifiers'

export class InputAboalarm {
  constructor(name, changeCallback = null) {
    this.el = document.querySelector(`[data-context="${name}"]`)
    if (!this.el) return

    this.previousValue = null
    this.defaultStateClass = 'c-form-element--state-default'
    this.inputIsValidClass = 'c-form-element__input--is-valid'
    this.inputIsInvalidClass = 'c-form-element__input--is-invalid'
    this.feedbackIsInvalidClass = 'c-form-element__feedback--is-invalid'
    this.inputEl = this.el.querySelector('input, textarea, select')
    this.feedbackEl = this.el.querySelector('[data-role="feedback"]')
    this.labelText = this.el.dataset.inputLabel
    this.inputIsRequired = this.el.dataset.required
    this.changeCallback = changeCallback ? changeCallback.bind(this) : this.noop.bind(this)

    if (this.inputEl) this.init()
  }

  init() {
    let invokeInitialCallback = false
    let throttleTimer = 0
    const debounceThreshold = 50
    this.el.classList.add(this.defaultStateClass)
    const inputMethods = ['input', 'focus', 'change', 'blur']
    this.debounceUpdateCallback = Debounce(this.debounceUpdate.bind(this, this.changeCallback), debounceThreshold)
    inputMethods.forEach((eventName) => {
      const evtCallback = `${eventName}Callback`
      this[evtCallback] = this[evtCallback] ? this[evtCallback].bind(this) : this.noop.bind(this)

      this.inputEl.addEventListener(eventName, () => {
        if (throttleTimer + debounceThreshold > Date.now()) return

        throttleTimer = Date.now()
        this.debounceUpdateCallback(eventName)
        this[evtCallback](eventName)
      })
    })

    window.addEventListener('pageshow', (e) => {
      this.blurCallback()
      this.debounceUpdateCallback(e)
      invokeInitialCallback = true
    })

    if (!invokeInitialCallback && document.readyState === 'complete') {
      this.blurCallback()
      this.debounceUpdateCallback()
    }
  }

  debounceUpdate(callback, e) {
    if (this.isPristine()) return

    if (this.previousValue !== this.value()) {
      callback(e)
      this.previousValue = this.value()
    }
  }

  noop() {
    return () => undefined
  }

  focusCallback() {
    this.removeClass(this.el, this.defaultStateClass)
  }

  blurCallback() {
    if (this.isPristine()) {
      this.addClass(this.el, this.defaultStateClass)
    } else if (!this.isBlank(this.value())) {
      this.removeClass(this.el, this.defaultStateClass)
    }
  }

  removeClass(el, className) {
    el.classList.remove(className)
  }

  addClass(el, className) {
    el.classList.add(className)
  }

  hasError(expectedMessage = null) {
    if (!this.isInvalid()) return false

    return expectedMessage == null || expectedMessage == this.errorMessage()
  }

  showError(error = null) {
    this.removeClass(this.inputEl, this.inputIsValidClass)
    this.addClass(this.inputEl, this.inputIsInvalidClass)
    if (!this.feedbackEl) return

    this.addClass(this.feedbackEl, this.feedbackIsInvalidClass)
    if (error) this.feedbackEl.innerHTML = error
  }

  hideError() {
    this.removeClass(this.inputEl, this.inputIsInvalidClass)
    if (this.feedbackEl) this.removeClass(this.feedbackEl, this.feedbackIsInvalidClass)
  }

  isInvalid() {
    return this.inputEl.classList.contains(this.inputIsInvalidClass)
  }

  isValid() {
    return this.inputEl.classList.contains(this.inputIsValidClass)
  }

  isRequired() {
    return this.inputIsRequired === 'true'
  }

  showValid() {
    this.removeClass(this.inputEl, this.inputIsInvalidClass)
    this.addClass(this.inputEl, this.inputIsValidClass)
  }

  hideValid() {
    if (this.isValid()) this.removeClass(this.inputEl, this.inputIsValidClass)
  }

  value() {
    if (this.inputEl.type === 'checkbox') return this.inputEl.checked
    return this.inputEl.value
  }

  name() {
    return this.inputEl.name
  }

  valueRepresentation() {
    const inputEl = this.inputEl
    if (inputEl.tagName === 'SELECT') return inputEl.options[inputEl.selectedIndex].text
    return this.value()
  }

  setValue(val) {
    if (this.inputEl.type === 'checkbox') {
      const isChecked = this.inputEl.checked
      if (isChecked !== val) return this.inputEl.click()
    } else {
      this.inputEl.value = val
    }
    this.blurCallback()
    this.debounceUpdateCallback()
    return val
  }

  show() {
    ShowElement(this.el)
  }

  hide() {
    HideElement(this.el)
  }

  label() {
    return this.labelText
  }

  setLabel(label) {
    this.el.dataset.inputLabel = label
    this.labelText = label
  }

  errorMessage() {
    return this.feedbackEl ? this.feedbackEl.innerHTML : null
  }

  isBlank(str) {
    return !str || /^\s*$/.test(str)
  }

  isPristine() {
    return this.isBlank(this.previousValue) && this.isBlank(this.value())
  }

  destroy() {
    CallAnimationFrame(() => {
      this.el.replaceWith(this.el.cloneNode(true))
    })
  }
}

import { setCookie, clearCookie, readCookie } from '../helpers/utilities'
import { Amplitude } from '../integrations/amplitude'
export class Voucher {
  /**
   * VendorSearch module is initiated preferably with a object payload
   * @param {Object} configObject the required parameter that contains all the fields in an object
   * @param {(string)} configObject.hiddenClassName A string value that is then mapped to DOM element as a class name to hide/show the element
   */
  constructor(configObject = {}) {
    this.voucherForm = document.querySelector('[data-context="voucher"]')
    this.voucherFromURL = false
    this.hiddenClassName = configObject.hiddenClassName

    this.displayDiscountBanner()
    this.setupForm()
    this.handleCodeOnURL()
  }

  static CURRENT_MARKET() {
    return document.querySelector('[data-current-market]').dataset.currentMarket
  }

  // This should be a static field/property but eslint only support it on version 8.0.0 with ecmaVersion 13 or 2022.
  // More: https://stackoverflow.com/a/69575848/529418
  static VOUCHER_COOKIE_KEY() {
    return `${this.CURRENT_MARKET()}Voucher`
  }

  /**
   * Clear the voucher data on the cookies.
   */
  static clearDataFromCookie() {
    clearCookie(this.VOUCHER_COOKIE_KEY())
  }

  /**
   * Get the voucher from saved on the cookies.
   *
   * @returns {Object} The voucher object
   */
  getDataFromCookie() {
    return JSON.parse(readCookie(this.constructor.VOUCHER_COOKIE_KEY()))
  }

  /**
   * Return the voucher code if present on the URL
   * @returns {String|null}
   */
  voucherOnURL() {
    if (!window['URLSearchParams']) {
      const paramMatch = location.search.match(/code=([^&]+)/)

      return paramMatch ? paramMatch[1] : null
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    if (!this.queryParams) this.queryParams = new URLSearchParams(location.search)

    return this.queryParams.get('code')
  }

  /**
   * When there is a code on the URL, and the page has the voucher form, it will apply and validate the voucher on the
   * form.
   *
   * When the page form do not has the voucher form, it will only find a correspondent voucher and set its data on the
   * cookies. When invalid or non existent code is given, clear the cookie data. It also show or hide the discount
   * banner accordingly.
   */
  handleCodeOnURL() {
    const code = this.voucherOnURL()

    if (!code) return

    if (this.voucherForm) {
      this.voucherVirtualInputField.setAttribute('value', code)
      this.validateAndApply()

      return
    }

    this.getVoucherDetails(code).then((data) => {
      if (!data.voucher) {
        this.constructor.clearDataFromCookie()
        this.hideDiscountBanner()

        return
      }

      setCookie(this.constructor.VOUCHER_COOKIE_KEY(), JSON.stringify(data.voucher))
      this.displayDiscountBanner()
    })
  }

  /**
   *
   * Get a voucher details from the API based on a given code.
   * @param {string} code The voucher code
   * @returns {Promise} A promise that will return the voucher details if found
   */
  getVoucherDetails(code) {
    const urlElement = document.querySelector('[data-find-vouchers-url]')

    if (!urlElement) return Promise.resolve()

    let codeTrimmed = code.trim().toUpperCase()
    let userEmail = this.voucherForm ? this.voucherForm.dataset.userEmail : ''
    const url = `${urlElement.dataset.findVouchersUrl}?code=${codeTrimmed}&valid_for_email=${userEmail}`

    return fetch(url.toString())
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        Rollbar.error(`There was a problem when fetching the voucher ${codeTrimmed}`, error)
        return Promise.resolve()
      })
  }

  /**
   * Display the discount banner when the voucher cookie is present
   */
  displayDiscountBanner() {
    const voucherData = this.getDataFromCookie()

    if (!voucherData) return

    const banner = document.querySelector('[data-context="discount-banner"]')

    if (!banner) return

    const precision = String(voucherData.amount).endsWith('00') ? 0 : 2
    banner.querySelector('[data-context="discount-banner-text"]').innerHTML = I18n.t('frontend.banner_promotion', {
      amount: I18n.numberToCurrency(voucherData.amount / 100, { precision: precision }),
    })
    banner.classList.remove(this.hiddenClassName)
    document.body.classList.add('discount-funnel')
  }

  /**
   * Hide the discount banner
   */
  hideDiscountBanner() {
    const banner = document.querySelector('[data-context="discount-banner"]')

    if (!banner) return

    banner.classList.add(this.hiddenClassName)
    document.body.classList.remove('discount-funnel')
  }

  /**
   * Setup the voucher form which the user submits the code to validate and checkout
   */
  setupForm() {
    if (!this.voucherForm) return

    this.isVoucherFormPresent = true

    this.voucherAddButton = this.voucherForm.querySelector('[data-context="voucher-add"]')

    this.voucherInput = this.voucherForm.querySelector('[data-context="voucher-input"]')

    this.voucherVirtualInputField = this.voucherForm.querySelector('[data-context="voucher-virtual-input-field"]')
    this.voucherRealInputField = document.querySelector('[data-context="voucher-real-input-field"]')

    this.voucherInputButton = this.voucherForm.querySelector('[data-context="voucher-input-submit"]')

    this.voucherSuccess = this.voucherForm.querySelector('[data-context="voucher-success"]')

    this.voucherClearButton = this.voucherForm.querySelector('[data-context="voucher-clear-submit"]')

    this.voucherError = this.voucherForm.querySelector('[data-context="voucher-error"]')
    this.voucherErrorButton = this.voucherForm.querySelector('[data-context="voucher-error-submit"]')

    this.voucherSections = [this.voucherAddButton, this.voucherInput, this.voucherSuccess, this.voucherError]

    // The voucher on URL has precedence over the cookie one. So, we only need to apply the voucher from cookies when
    // there is no voucher on the URL
    if (!this.voucherOnURL()) this.validateAndApplyOnFormFromCookies()
    this.setupFormListeners()
  }

  /**
   * Setup the listeners related to the voucher form
   */
  setupFormListeners() {
    this.voucherAddButton.addEventListener('click', (e) => {
      e.preventDefault()
      this.showFormSection(this.voucherInput)
      this.voucherVirtualInputField.focus()
      Amplitude.logEvent('Vouchers Add discount code Button Clicked')
    })

    this.voucherInputButton.addEventListener('click', this.formEventResolver.bind(this))
    this.voucherVirtualInputField.addEventListener('keypress', this.formEventResolver.bind(this))

    this.voucherClearButton.addEventListener('click', (e) => {
      e.preventDefault()
      this.voucherVirtualInputField.value = ''
      this.voucherRealInputField.value = ''
      this.showFormSection(this.voucherInput)
      this.voucherVirtualInputField.focus()
      this.emitAmountChanged(0)
      this.hideDiscountBanner()
      this.constructor.clearDataFromCookie()
    })

    this.voucherErrorButton.addEventListener('click', (e) => {
      e.preventDefault()
      this.voucherVirtualInputField.value = ''
      this.voucherRealInputField.value = ''
      this.showFormSection(this.voucherInput)
      this.voucherVirtualInputField.focus()
    })
  }

  /**
   * Display only the given from section and hide all the others
   * @param {Element} section The form section to be displayed
   */
  showFormSection(section) {
    if (section.classList.contains(this.hiddenClassName)) {
      this.voucherSections.forEach((el) => {
        el.classList.add(this.hiddenClassName)
      })

      section.classList.add('u-display:initial')
      section.classList.remove(this.hiddenClassName)
    }
  }

  /**
   * If there is some voucher on the cookies, use it on the form, validate and apply it.
   */
  validateAndApplyOnFormFromCookies() {
    if (!this.voucherForm) return

    const voucherData = this.getDataFromCookie()

    if (voucherData) {
      this.voucherFromURL = true
      this.voucherVirtualInputField.setAttribute('value', voucherData.code)
      this.validateAndApply()

      let amplitudeData = {
        nameOfCodeApplied: voucherData.code,
        codeApplicationMethod: 'autofill',
      }
      Amplitude.logEvent('Voucher Applied', amplitudeData)
    }
  }

  /**
   * Resolve the event triggered when the voucher form is submitted. When it is a button click or the "Enter" key press,
   * validate the voucher.
   * @param {Event} evt The triggered event
   */
  formEventResolver(evt) {
    if (evt.type === 'click' || (evt.type === 'keypress' && (evt.key === 'Enter' || evt.keyCode === 13))) {
      evt.preventDefault()
      this.validateAndApply()
    }
  }

  voucherErrorCallback(message) {
    this.showFormSection(this.voucherError)
    this.voucherError.querySelector('[data-context="voucher-error-message"]').innerHTML = message
    this.hideDiscountBanner()
    this.voucherVirtualInputField.value = ''
    this.constructor.clearDataFromCookie()
  }

  /**
   * If there is a voucher code on the virtual field, validate and apply it to the real field, presenting a message
   * accordingly, otherwise present the accurate error.
   */
  validateAndApply() {
    const code = this.voucherVirtualInputField.value.trim().toUpperCase()

    if (!code.length) {
      this.voucherErrorCallback(I18n.t('frontend.checkout_form.vouchers.errors.voucher_no_match'))
      Amplitude.logEvent('Voucher Invalid Code', { errorType: 'Voucher code is empty' })
      return
    }

    this.getVoucherDetails(code).then((data) => {
      if (data.voucher) {
        this.voucherRealInputField.value = this.voucherVirtualInputField.value
        this.showFormSection(this.voucherSuccess)
        this.voucherSuccess.querySelector(
          '[data-context="voucher-applied-code"]'
        ).innerHTML = I18n.t('frontend.checkout_form.vouchers.voucher_applied', { voucher_code: data.voucher.code })
        setCookie(this.constructor.VOUCHER_COOKIE_KEY(), JSON.stringify(data.voucher))
        this.displayDiscountBanner()
        this.emitAmountChanged(data.voucher.amount, data.voucher.id)

        if (this.voucherFromURL === false) {
          let amplitudeData = {
            nameOfCodeApplied: this.voucherRealInputField.value,
            codeApplicationMethod: 'filled manually',
          }

          Amplitude.logEvent('Voucher Applied', amplitudeData)
        }
      } else {
        switch (data.error.code) {
          case 'NO_MATCH': {
            this.voucherErrorCallback(I18n.t('frontend.checkout_form.vouchers.errors.voucher_no_match'))

            Amplitude.logEvent('Voucher Invalid Code', { errorType: 'Non-existent' })

            break
          }
          case 'INVALID_DATE': {
            this.voucherErrorCallback(I18n.t('frontend.checkout_form.vouchers.errors.voucher_invalid'))

            Amplitude.logEvent('Voucher Invalid Code', { errorType: 'Expired' })

            break
          }
          case 'TOTAL_LIMIT_EXCEEDED': {
            this.voucherErrorCallback(I18n.t('frontend.checkout_form.vouchers.errors.voucher_total_limit_exceeded'))

            Amplitude.logEvent('Voucher Invalid Code', { errorType: 'Total limit reached' })

            break
          }
          case 'PER_USER_LIMIT_EXCEEDED': {
            this.voucherErrorCallback(I18n.t('frontend.checkout_form.vouchers.errors.voucher_per_user_limit_exceeded'))

            Amplitude.logEvent('Voucher Invalid Code', { errorType: 'Per-customer limit reached' })

            break
          }
          default:
            this.voucherErrorCallback(I18n.t('frontend.checkout_form.vouchers.errors.voucher_no_match'))

            Amplitude.logEvent('Voucher Invalid Code', { errorType: 'Generic default error' })

            break
        }
      }
    })

    if (this.voucherFromURL === false) {
      Amplitude.logEvent('Vouchers Apply Button Clicked')
    }
  }

  /**
   * Emit the 'vouchers:amountChanged' event when the voucher amount have changed, usually after a validation
   * was successful. The event have the voucher amount on the event detail.
   * @param {Numeric} amount The voucher amount
   */
  emitAmountChanged(amount, voucherId) {
    document.dispatchEvent(
      new CustomEvent(`${this.constructor.CURRENT_MARKET()}:vouchers:amountChanged`, {
        detail: { amount, voucherId },
      })
    )
  }
}

// detect if localStorage is available and writable
export function localStorageAvailable() {
  if (window.LOCAL_STORAGE_AVAILABLE === undefined) {
    var key = 'test_key_for_local_storage_verification'
    try {
      window.localStorage.setItem(key, key)
      window.localStorage.removeItem(key)
      window.LOCAL_STORAGE_AVAILABLE = true
    } catch (_) {
      window.LOCAL_STORAGE_AVAILABLE = false
    }
  }

  return window.LOCAL_STORAGE_AVAILABLE
}

export function localStorageSetItem(key, value) {
  try {
    return window.localStorage.setItem(key, value)
  } catch (_) {
    return undefined
  }
}

export function localStorageGetItem(key) {
  try {
    return window.localStorage.getItem(key)
  } catch (e) {
    if (e.name === 'NS_ERROR_FILE_CORRUPTED') {
      window.localStorage.removeItem(key)
      return null
    }
  }
}

export function localStorageRemoveItem(key) {
  return window.localStorage.removeItem(key)
}

export function sessionStorageAvailable() {
  if (window.SESSION_STORAGE_AVAILABLE === undefined) {
    var key = 'test_key_for_session_storage_verification'
    try {
      window.sessionStorage.setItem(key, key)
      window.sessionStorage.removeItem(key)
      window.SESSION_STORAGE_AVAILABLE = true
    } catch (_) {
      window.SESSION_STORAGE_AVAILABLE = false
    }
  }

  return window.SESSION_STORAGE_AVAILABLE
}

export function sessionStorageSetItem(key, value) {
  try {
    return window.sessionStorage.setItem(key, value)
  } catch (_) {
    return undefined
  }
}

export function sessionStorageGetItem(key) {
  try {
    return window.sessionStorage.getItem(key)
  } catch (e) {
    if (e.name === 'NS_ERROR_FILE_CORRUPTED') {
      window.sessionStorage.removeItem(key)
      return null
    }
  }
}

export function sessionStorageRemoveItem(key) {
  return window.sessionStorage.removeItem(key)
}

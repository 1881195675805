import { InputAboalarm } from '../components/input_aboalarm'
import { ShowElement, HideElement } from '../helpers/element-modifiers'

export class VendorsOverview {
  constructor() {
    this.el = document.querySelector('[data-context="vendors-overview"]')
    if (!this.el) return
    this.init()
  }

  init() {
    this.searchVendors = new InputAboalarm('search-vendors', () => {
      this.liveSearch()
    })
  }

  liveSearch() {
    let vendors = document.querySelectorAll('.c-linklist__link')
    let searchQuery = this.searchVendors.value().toLowerCase()

    for (const vendor of vendors) {
      vendor.dataset.searchableVendorName.includes(searchQuery) ? ShowElement(vendor) : HideElement(vendor)
    }
  }
}

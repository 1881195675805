/**
 * ⚠️ Shared module that's in use in both Volders and Aboalarm.
 */

import Rails from '@rails/ujs'
import { InputVincent } from '../../components/input_vincent'
import { InputAboalarm } from '../../aboalarm/components/input_aboalarm'
import { ToggleCheckbox } from '../../components/toggle_checkbox'
import { Amplitude } from '../../integrations/amplitude'

export class UpdatePasswordForm {
  constructor() {
    this.form = document.querySelector('[data-context="updatePassword"]')
    if (!this.form) return

    this.currentMarket = document.body.dataset.currentMarket
    this.submitButton = this.form.querySelector('button[type="submit"]')
    this.submitButtonText =
      this.currentMarket === 'aboalarm'
        ? this.submitButton.querySelector('span')
        : this.submitButton.querySelector('.btn__text')

    this.setMarketVariables()
    this.setButtonMode('disabled')
    this.currentPasswordValid = false
    this.initFields()
    this.captureSubmit()
    this.captureSuccess()
  }

  setMarketVariables() {
    if (this.currentMarket === 'aboalarm') {
      return (this.variables = {
        newPassword: 'new-password',
        newPasswordConfirmation: 'new-password-confirmation',
        currentPassword: 'current-password',
        btnSuccessMessage: 'btn--success-message',
        hasLoadingState: 'btn--has-loading-state',
      })
    }

    this.variables = {
      newPassword: 'newPassword',
      newPasswordConfirmation: 'newPasswordConfirmation',
      currentPassword: 'currentPassword',
      btnSuccessMessage: 'btn--successMessage',
      hasLoadingState: 'btn--hasLoadingState',
    }
  }

  initFields() {
    this.fields = {}
    this.initField(this.variables.newPassword, () => {
      this.validatePasswordLength(this.variables.newPassword)
      this.validateNewPasswordConfirmation()
      this.updateFormStatus()
    })
    this.initField(this.variables.newPasswordConfirmation, () => {
      this.validateNewPasswordConfirmation()
      this.updateFormStatus()
    })
    this.initField(this.variables.currentPassword, () => {
      this.validatePasswordLength(this.variables.currentPassword)
      this.updateFormStatus()
    })

    if (this.currentMarket === 'volders') {
      new ToggleCheckbox('SmsReminder', (input) => {
        this.changeNotification(input)
      })
      new ToggleCheckbox('EmailReminder', (input) => {
        this.changeNotification(input)
      })
    }

    this.updateFormStatus()
  }

  initField(name, callback) {
    this.fields[name] =
      this.currentMarket === 'aboalarm'
        ? new InputAboalarm(`${name}`, callback)
        : new InputVincent(`UpdatePasswordForm-${name}`, callback)
  }

  validatePasswordLength(fieldName) {
    const passwordField = this.fields[fieldName]

    if (passwordField.value().length >= 8) {
      passwordField.hideError()

      return true
    }

    if (this.formSubmitted) {
      passwordField.showError(I18n.t('frontend.change_password.errors.password_too_short'))
    }

    return false
  }

  validateNewPasswordConfirmation() {
    const newPasswordField = this.fields[this.variables.newPassword]
    const newPasswordConfirmationField = this.fields[this.variables.newPasswordConfirmation]

    if (newPasswordField.value() == newPasswordConfirmationField.value()) {
      newPasswordConfirmationField.hideError()

      return true
    }

    if (this.formSubmitted) {
      newPasswordConfirmationField.showError(I18n.t('frontend.change_password.errors.password_mismatch'))
    }

    return false
  }

  validateCurrentPasswordMatch() {
    const currentPasswordField = this.fields[this.variables.currentPassword]
    const currentPasswordSelector =
      this.currentMarket === 'aboalarm' ? currentPasswordField.el : currentPasswordField.input
    const path = currentPasswordSelector.dataset.validatePasswordPath
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ current_password: currentPasswordField.value() }),
    }

    fetch(path, requestOptions).then((resp) => {
      if (resp.ok) {
        this.currentPasswordValid = true
        Rails.fire(this.form, 'submit')
      } else {
        currentPasswordField.showError(I18n.t('frontend.change_password.errors.current_password_invalid'))
        this.updateFormStatus()
      }
    })
  }

  updateFormStatus() {
    let formIsBlank = true

    for (var fieldName in this.fields) {
      if (this.fields[fieldName].hasError()) {
        return this.setButtonMode('disabled')
      }

      if (formIsBlank && this.fields[fieldName].value().length != 0) {
        formIsBlank = false
      }
    }

    if (formIsBlank) {
      return this.setButtonMode('disabled')
    }

    this.setButtonMode('ready')
  }

  changeNotification(input) {
    const formData = new FormData()

    formData.append(input.name, input.checked)

    fetch(input.dataset.url, { method: 'PATCH', body: formData })
      .then((response) => {
        if (!response.ok) throw new Error('Not 2xx response')
        return response
      })
      .then(() => {
        this.sendNotificationChangeForAmplitude(input)
      })
      .catch(() => {
        // fetch failed, reverting the checkbox and the state
        const unSuccessfulState = input.checked
        input.checked = !unSuccessfulState
      })
  }

  sendNotificationChangeForAmplitude(input) {
    const notificationStatus = input.checked ? 'on' : 'off'
    switch (input.name) {
      case 'email_reminder':
        Amplitude.logEvent('MAX settings email toggle', {
          email_reminder_status: notificationStatus,
        })
        break
      case 'sms_reminder':
        Amplitude.logEvent('MAX settings sms toggle', {
          sms_reminder_status: notificationStatus,
        })
        break
    }
  }

  sendPasswordChangeForAmplitude() {
    Amplitude.logEvent('MAX setting password changed')
  }

  captureSubmit() {
    this.form.addEventListener('ajax:before', (e) => {
      if (this.currentPasswordValid) return

      e.preventDefault()
      this.setButtonMode('disabled')
      this.formSubmitted = true
      let validations = [
        this.validatePasswordLength(this.variables.currentPassword),
        this.validatePasswordLength(this.variables.newPassword),
        this.validateNewPasswordConfirmation(),
      ]

      if (validations.every((valid) => valid)) {
        this.setButtonMode('spinning')
        this.validateCurrentPasswordMatch()
      }

      return false
    })
  }

  setButtonMode(mode) {
    this.submitButton.classList.remove('btn--success')
    this.submitButton.classList.remove(this.variables.hasLoadingState)
    this.submitButton.classList.remove(this.variables.btnSuccessMessage)
    this.submitButton.removeAttribute('disabled')
    clearTimeout(this.buttonTimeout)

    if (!this.originalButtonText) {
      this.originalButtonText = this.submitButtonText.innerText
    }
    this.submitButtonText.innerText = this.originalButtonText

    if (mode == 'disabled') {
      this.submitButton.setAttribute('disabled', true)
    }
    if (mode == 'ready') {
      this.submitButton.classList.add('btn--success')
    }
    if (mode == 'spinning') {
      this.submitButton.setAttribute('disabled', true)
      this.submitButton.classList.add(this.variables.hasLoadingState)
    }
    if (mode == 'success') {
      this.submitButton.classList.add(this.variables.btnSuccessMessage)
      this.submitButtonText.innerText = I18n.t('frontend.change_password.saved')
    }
  }

  captureSuccess() {
    this.form.addEventListener('ajax:success', () => {
      this.setButtonMode('success')
      this.form.reset()
      this.formSubmitted = false
      this.currentPasswordValid = false
      this.sendPasswordChangeForAmplitude()

      this.buttonTimeout = setTimeout(() => {
        this.setButtonMode('disabled')
      }, 3000)
    })
  }
}

import {
  CallAnimationFrame,
  Debounce,
  DispatchEventByName,
  fetchZipcodeErrorMessageForCountry,
  GetUrlParameter,
  IsValidDate,
  IsValidEmailAddress,
  IsValidYear,
  validateZipcodeForCountryForAboalarm,
  InitInputMaskFor,
  IsStoredFieldsValid,
} from '../../helpers/utilities'
import {
  localStorageAvailable,
  localStorageSetItem,
  localStorageGetItem,
  localStorageRemoveItem,
  sessionStorageAvailable,
  sessionStorageSetItem,
  sessionStorageGetItem,
  sessionStorageRemoveItem,
} from '../../vendor/storage_utilities'
import { ShowElement, HideElement } from '../helpers/element-modifiers'
import { lowerCaseToDashCase, camelCaseToLowerSnakeCase, lowerCaseToKebabCase } from '../helpers/string-modifiers'
import { InputAboalarm } from '../components/input_aboalarm'
import { Amplitude } from '../../integrations/amplitude'
import { GoogleTagManager } from '../../integrations/google_tag_manager'
import { SignatureUploader } from '../cancellation/signature_uploader'
import { CancellationFormFields } from './cancellation-form-fields'
import { reDrawTooltipBottom } from '../helpers/tooltip-popover'
import { AttachmentUploader } from '../../helpers/attachment_uploader'
import { VWO } from '../../integrations/vwo'

export class CancellationModal {
  constructor() {
    this.el = document.querySelector('[data-context="cancellation"]')
    if (!this.el) return

    this.listenCancellationModal()
  }

  listenCancellationModal() {
    window.addEventListener('aboalarm:openCancellationModal', () => {
      document.querySelector('[data-context="cancellation"]').setAttribute('aria-hidden', false)
      document.body.setAttribute('data-c-dialog', 'open')
      this.init()
    })
    window.addEventListener('aboalarm:closeCancellationModal', () => {
      document.querySelector('[data-context="cancellation"]').setAttribute('aria-hidden', true)
      document.body.setAttribute('data-c-dialog', 'closed')
      this.destroy()
    })
  }

  init() {
    this.introduceKeyElements()
    this.introduceProps()
    this.introduceFormElements()
    this.introduceIdentificationFormElements()
    this.mapFormFields()
    this.introduceCancellationTypeList()
    this.introduceCustomVendorFormElements()
    this.introduceStepValidations()
    this.listenQueryParameters()
    this.adjustUIforStepChange()
    this.listenStepChange()
    this.displayBackButton()
    this.displayCancellationGuaranteePopover()
    this.subscribeEvents()
    this.listenBlockPhoneNumberSelection()
    this.listenStopMarketingOffers()
    this.sendInitialTrackingEvents()
    this.initLetterAmplitudeEvents()
    this.invokeSignatureField()
    this.invokeDeleteMyDataField()
    this.fillInFormFieldData()
    this.initRemovePreviewExperiment()
  }

  initRemovePreviewExperiment() {
    const experimentCallback = () => {
      this.totalSteps = this.totalSteps - 1
      sessionStorageSetItem('RemovePreviewExperiment', 'active')
    }

    if (this.hasPreviewExperimentSet()) return experimentCallback()

    window.addEventListener('aboalarm:RemovePreviewExperiment', experimentCallback)
    window.VWO = window.VWO || []
    window.VWO.push(['activate', false, [58], true])
  }

  hasPreviewExperimentSet() {
    return sessionStorageAvailable() && sessionStorageGetItem('RemovePreviewExperiment') === 'active'
  }

  subscribeEvents() {
    this.nextButton.addEventListener('click', this.nextButtonCallback.bind(this))
    this.duplicatedNextButton.addEventListener('click', this.nextButtonCallback.bind(this))
    this.closeButton.addEventListener('click', this.closeButtonCallback.bind(this))
    this.backButton.addEventListener('click', this.backButtonCallback.bind(this))
    this.resetTextButton.addEventListener('click', this.resetCancellationTextButtonCallback.bind(this))
    this.form.addEventListener('submit', this.formSubmitCallback.bind(this))
    this.letterPreview.addEventListener('click', this.letterPreviewClickHandler.bind(this))
  }

  unsubscribeEvents() {
    if (!this.nextButton) return // null check if this method is immediately invoked by handlePageShow in CancellationPage controller prior to introducing the key elements

    this.nextButton.removeEventListener('click', this.nextButtonCallback)

    this.duplicatedNextButton.removeEventListener('click', this.nextButtonCallback)
    this.closeButton.removeEventListener('click', this.closeButtonCallback)
    this.backButton.removeEventListener('click', this.backButtonCallback)
    this.resetTextButton.removeEventListener('click', this.resetCancellationTextButtonCallback)
    this.form.removeEventListener('submit', this.formSubmitCallback)
    this.letterPreview.removeEventListener('click', this.letterPreviewClickHandler)
  }

  introduceKeyElements() {
    this.el = document.querySelector('[data-context="cancellation"]')
    this.backButton = this.el.querySelector('[data-context="back-button"]')
    this.nextButton = this.el.querySelector('[data-context="next-button"]')
    this.duplicatedNextButton = this.el.querySelector('[data-context="duplicated-next-button"]')
    this.closeButton = this.el.querySelector('[data-context="close-button"]')
    this.resetTextButton = this.el.querySelector('[data-context="reset-text-button"]')
    this.form = this.el.querySelector('[data-context="cancellation-form"]')
    this.letterPreview = this.el.querySelector('[data-context="preview-letter"]')
  }

  introduceProps() {
    this.step = parseInt(this.el.dataset.currentStep, 10)
    this.totalSteps = parseInt(this.el.dataset.totalSteps, 10)
    this.pristine = true
    this.localStorageAvailable = localStorageAvailable()
    this.amplitudeData = window['ANALYTICS_DATA']
    this.letterSubjectDefault = this.el.querySelector('[data-context="letter-subject"] input').value
    this.letterTextareaDefault = this.el.querySelector('[data-context="letter-text-field"]').value
    this.resetTextBox = document.querySelector('[data-context="cancellation-change-text"]')
    this.vendor = this.el.querySelector('[name="vendor_name"]').value
    this.inputToBeFocused = null
    this.isForCustomVendor = this.el.querySelector('[data-is-for-custom-vendor]')
    this.selectedCancellationType = this.el.querySelector('[data-context="cancellation-type"] select').value
    this.isMobileFlow = this.el.dataset.mobileFlow
    this.signatureRequired = !!this.el.querySelector('[data-context="signature-section"]')
    this.blockPhoneRequestForLetterCopy = null
    this.stopMarketingOfferCheck = null
    this.userSignedIn = this.el.dataset.userSignedIn == 'true'
  }

  invokeSignatureField() {
    if (!this.signatureRequired) return

    this.signatureUploader = new SignatureUploader(this.amplitudeData)
    this.signatureUploader.el.addEventListener('aboalarm:userSetSignatureCallback', this.persistFieldValues.bind(this))
  }

  invokeDeleteMyDataField() {
    const deleteMyDataEl = this.el.querySelector('[data-context="delete-my-data"]')
    if (!deleteMyDataEl) return

    let hasPushedAmplitude = false
    const checkboxChange = (checked) => {
      const lastLetterText = this.letterText.value()
      const copyToAppend = `\n\n${deleteMyDataEl.dataset.textToAppendToLetter}`
      if (checked) {
        if (sessionStorageAvailable()) sessionStorageSetItem('data_deletion', 'consent_given')
        this.letterText.setValue(`${lastLetterText}${copyToAppend}`)
        if (!hasPushedAmplitude) {
          Amplitude.logEvent('CS Form Page Data Deletion Survey Selected')
          hasPushedAmplitude = true
        }
      } else {
        if (sessionStorageAvailable()) sessionStorageRemoveItem('data_deletion')
        if (lastLetterText.includes(copyToAppend)) {
          this.letterText.setValue(`${lastLetterText}`.replace(`${copyToAppend}`, ''))
        }
      }
      DispatchEventByName('input', this.letterText.inputEl)
    }

    deleteMyDataEl.querySelector('input').addEventListener('change', (e) => checkboxChange(e.target.checked))
  }

  introduceFormElements() {
    const throttledZipcodeCallback = Debounce(this.autoFillCityCountry.bind(this), 250)
    this.persistFieldValues = Debounce(this.persistFormFieldData.bind(this), 250)
    this.cancellationTypeDropdown = new InputAboalarm(
      'cancellation-type',
      Debounce(this.cancellationDropdownCallback.bind(this))
    )
    this.contactFirstName = new InputAboalarm('firstname', () => {
      this.validate({ fieldName: 'contactFirstName', errorField: 'presence_firstname' })
      this.persistFieldValues()
    })
    this.contactLastName = new InputAboalarm('lastname', () => {
      this.validate({ fieldName: 'contactLastName', errorField: 'presence_lastname' })
      this.persistFieldValues()
    })
    this.contactStreet = new InputAboalarm('street', () => {
      this.validate({ fieldName: 'contactStreet', errorField: 'presence_street' })
      this.persistFieldValues()
    })
    this.contactStreetNumber = new InputAboalarm('street-number', () => {
      this.validate({ fieldName: 'contactStreetNumber', errorField: 'presence_street_number' })
      this.persistFieldValues()
    })
    this.contactZipcode = new InputAboalarm('zipcode', () => {
      this.validateZipCode('contactZipcode', 'contactCountry')
      throttledZipcodeCallback()
      this.persistFieldValues()
    })
    this.contactCity = new InputAboalarm('city', () => {
      this.validate({ fieldName: 'contactCity', errorField: 'presence_city' })
      this.persistFieldValues()
    })
    this.contactCountry = new InputAboalarm('country', () => {
      this.validate({ fieldName: 'contactCountry' })
      this.validateZipCode('contactZipcode', 'contactCountry')
      this.persistFieldValues()
    })
    this.contactEmail = new InputAboalarm('email', () => {
      this.validateEmail()
      this.persistFieldValues()
    })
    this.letterSubject = new InputAboalarm('letter-subject', () => {
      this.validate({ fieldName: 'letterSubject' })
      this.letterTextSubjectCallback()
      this.persistFieldValues()
    })
    this.letterText = new InputAboalarm('letter-text', () => {
      this.validate({ fieldName: 'letterText' })
      this.letterTextSubjectCallback()
      this.persistFieldValues()
    })
    this.identificationFields = []
  }

  mapFormFields() {
    this.cancellationFormFields = CancellationFormFields
    this.fields = this.cancellationFormFields.filter((field) => field.type === 'static').map((field) => field.name)
    if (this.contractOwnerBirthDate) this.fields.push('contractOwnerBirthDate')
  }

  sendInitialTrackingEvents() {
    Amplitude.logEvent('CS Form Page Pageview', {
      ...this.amplitudeData,
    })
    this.trackCurrentStep()
  }

  initLetterAmplitudeEvents() {
    this.letterSubject.inputEl.addEventListener('change', () => {
      GoogleTagManager.pushDataLayerEvent('cancellationSubjectEdited')
      Amplitude.logEvent('CS Form Page Edited', {
        ...this.amplitudeData,
        inputEdited: 'subject',
      })
    })

    this.letterText.inputEl.addEventListener('change', () => {
      GoogleTagManager.pushDataLayerEvent('cancellationLetterEdited')
      Amplitude.logEvent('CS Form Page Edited', {
        ...this.amplitudeData,
        inputEdited: 'letter',
      })
    })
  }

  introduceIdentificationFormElements() {
    const identificationFieldsWrapper = this.el.querySelector('[data-context="identification-fields"]')
    const identificationElements = identificationFieldsWrapper.querySelectorAll('[data-context]')
    const createIdentificationFieldInstance = (id) => {
      this[id] = new InputAboalarm(id, () => {
        this.validateIdentificationField(id)
        this.persistFieldValues()
      })
      this.identificationFields.push(id)
    }
    this.minimumFieldsRequired = parseInt(identificationFieldsWrapper.dataset.minimumFieldsRequired, 10)
    identificationElements.forEach((el) => createIdentificationFieldInstance(el.dataset.context))
    this.introduceContractOwnerBirthDateFormElement()
  }

  introduceContractOwnerBirthDateFormElement() {
    if (!document.querySelector('[data-context="contact-date-of-birth"]')) return

    this.contractOwnerBirthDate = new InputAboalarm('contact-date-of-birth', () => {
      this.validateDate('contractOwnerBirthDate', 'presence_date_of_birth')
      this.persistFieldValues()
    })
    InitInputMaskFor(this.contractOwnerBirthDate.inputEl)
  }

  introduceCustomVendorFormElements() {
    if (!this.isForCustomVendor) return

    this.customVendorName = new InputAboalarm('custom-vendor-name', () => {
      this.validate({ fieldName: 'customVendorName' })
      this.persistFieldValues()
    })
    this.customVendorStreet = new InputAboalarm('custom-vendor-street', () => {
      this.validate({ fieldName: 'customVendorStreet' })
      this.persistFieldValues()
    })
    this.customVendorStreetNumber = new InputAboalarm('custom-vendor-street-number', () => {
      this.validate({ fieldName: 'customVendorStreetNumber' })
      this.persistFieldValues()
    })
    this.customVendorZipcode = new InputAboalarm('custom-vendor-zipcode', () => {
      this.validateZipCode('customVendorZipcode', 'customVendorCountry')
      this.persistFieldValues()
    })
    this.customVendorCity = new InputAboalarm('custom-vendor-city', () => {
      this.validate({ fieldName: 'customVendorCity' })
      this.persistFieldValues()
    })
    this.customVendorCountry = new InputAboalarm('custom-vendor-country', () => {
      this.validate({ fieldName: 'customVendorCountry' })
      this.validateZipCode('customVendorZipcode', 'customVendorCountry')
      this.persistFieldValues()
    })
  }

  introduceCancellationTypeList() {
    this.cancellationTypeList = []
    const selectedCancellationTypes = ['union', 'moving', 'deceased', 'damage']
    selectedCancellationTypes.forEach((type) => {
      let rawFields = this.cancellationFormFields.filter((field) => field.type === type)
      let inputFields = rawFields.map((field) => camelCaseToLowerSnakeCase(field.name))
      if (type === 'deceased') {
        this.checkboxDependentDeathSpecialInputFields = inputFields.filter(
          (inputField) =>
            !['death_first_name', 'death_last_name', 'death_address_is_different', 'death_date'].includes(inputField)
        )
      }
      if (type !== 'damage') inputFields.push('fileInput')
      this.cancellationTypeList.push({
        name: type,
        inputFields,
      })
    })
    this.secondStepInputFields = this.cancellationFormFields
      .filter((field) => field.step === 2 && selectedCancellationTypes.includes(field.type))
      .map((field) => camelCaseToLowerSnakeCase(field.name))
  }

  backButtonCallback() {
    const prevStep = this.step - 1
    if (prevStep < 1) return

    let eventsPayload = {
      cancellationFormStep: this.getStepName(this.step),
      slug: ANALYTICS_DATA.slug,
      brandName: ANALYTICS_DATA.brand,
    }
    Amplitude.logEvent('CS Form Step Back Button', eventsPayload)
    this.changeStep(prevStep)
  }

  displayBackButton() {
    const appBackButton = this.el.querySelector('[data-context="app-back-button"]')
    if (appBackButton) {
      const displayAppBackButtonMethod = this.step === 1 ? ShowElement : HideElement
      displayAppBackButtonMethod(appBackButton)
    }

    const displayBackButtonMethod = this.step === 1 ? HideElement : ShowElement
    let backButtonNode = this.step === this.totalSteps ? 'preview_step_back_button' : 'back_button'
    displayBackButtonMethod(this.backButton)
    this.backButton.querySelector('[data-context="back-button-text"]').innerText = I18n.t(
      `frontend.cancellation_form.${backButtonNode}`
    )
  }

  displayCancellationGuaranteePopover() {
    const button = this.el.querySelector('[data-popover="cancellation-guarantee"]')
    const tooltip = this.el.querySelector('[data-popover-tooltip="cancellation-guarantee"]')
    const arrowElement = this.el.querySelector('[data-popover-arrow="cancellation-guarantee"]')
    let isVisible = false
    let trackedEvent = false
    if (!button || !tooltip) return

    const closeOnClickOutside = (e) => {
      if (e.target !== button) hideTooltip()
    }
    const listenBodyClickListener = closeOnClickOutside.bind(this)
    const showTooltip = () => {
      ShowElement(tooltip)
      isVisible = true
      reDrawTooltipBottom(button, tooltip, arrowElement)
      document.body.addEventListener('click', listenBodyClickListener)
      if (!trackedEvent) {
        Amplitude.logEvent('CS Form Step Click on CaGu', { slug: ANALYTICS_DATA.slug })
        trackedEvent = true
      }
    }
    const hideTooltip = () => {
      HideElement(tooltip)
      isVisible = false
      document.body.removeEventListener('click', listenBodyClickListener)
    }

    button.addEventListener('click', () => {
      isVisible ? hideTooltip() : showTooltip()
    })
  }

  assemblePreviewLetterFields() {
    const filterAndPushFields = (type) => {
      const filteredList = this.cancellationFormFields.filter((field) => field.type === type)
      filteredList.forEach((field) => letterFields.push(field.name))
    }
    let letterFields = [
      'contactFirstName',
      'contactLastName',
      'contactStreet',
      'contactStreetNumber',
      'contactZipcode',
      'contactCity',
      'letterSubject',
      'letterText',
    ]
    if (this.contractOwnerBirthDate) letterFields.push('contractOwnerBirthDate')
    if (this.identificationFields.length) {
      this.identificationFields.map((id) => letterFields.push(id))
    }
    if (this.isForCustomVendor) {
      filterAndPushFields('custom')
    }
    if (['moving', 'deceased', 'damage'].includes(this.selectedCancellationType)) {
      filterAndPushFields(this.selectedCancellationType)
    }
    return letterFields
  }

  renderPreviewLetter() {
    const genericSignatureEl = this.el.querySelector('[data-context="letter-signature-generic"]')
    const nameSurnameEl = this.el.querySelector('[data-context="letter-signature-name"]')
    const nameDisplay = `${this.contactFirstName.value()} ${this.contactLastName.value()}`
    const letterFields = this.assemblePreviewLetterFields()
    letterFields.map((letterField) => {
      if (letterField === 'deathAddressIsDifferent') return

      const previewLetterLink = this.el.querySelector(`[data-context="preview-letter-${letterField}"]`)
      const letterFieldValueRepresentation = this[letterField].valueRepresentation()
      previewLetterLink.innerHTML = letterFieldValueRepresentation

      if (letterField === 'letterText') {
        if (this.stopMarketingOfferCheck || this.blockPhoneRequestForLetterCopy) {
          let additionalCopyForLetterText = ''
          if (this.blockPhoneRequestForLetterCopy)
            additionalCopyForLetterText += `\n\n${this.blockPhoneRequestForLetterCopy}`
          if (this.stopMarketingOfferCheck) additionalCopyForLetterText += `\n\n${this.stopMarketingOfferCheck}`
          previewLetterLink.innerHTML = `${letterFieldValueRepresentation}${additionalCopyForLetterText}`
        }
      }

      if (this.identificationFields.includes(letterField)) {
        const parentDomEl = previewLetterLink.parentElement
        letterFieldValueRepresentation ? ShowElement(parentDomEl) : HideElement(parentDomEl)
      }

      if (letterField === 'deathStreet') {
        const parentDomEl = previewLetterLink.parentElement
        this.getCheckedStatusForDifferentAddressOfDeceased() && letterFieldValueRepresentation
          ? ShowElement(parentDomEl)
          : HideElement(parentDomEl)
      }

      if (letterField === 'customVendorCountry') {
        const selectEl = this[letterField].inputEl
        const isGermanySelected = selectEl[selectEl.selectedIndex].dataset.countryCode === 'DE'
        isGermanySelected ? HideElement(previewLetterLink) : ShowElement(previewLetterLink)
      }
    })

    if (genericSignatureEl) genericSignatureEl.innerHTML = nameDisplay
    if (nameSurnameEl) nameSurnameEl.innerHTML = nameDisplay
  }

  nextButtonCallback(e) {
    e.preventDefault()
    if (this.form.requestSubmit) {
      Amplitude.logEvent('CS Form Page Bottom CTA Clicked')
      this.form.requestSubmit()
    } else {
      const submitCallbackResponse = this.formSubmitCallback()
      if (submitCallbackResponse) this.form.submit()
    }
  }

  formSubmitCallback(e) {
    this.pristine = false
    const isFormValid = this.validateForm()
    if (this.step < this.totalSteps) {
      if (isFormValid) {
        this.vwoStepAcknowledge()
        this.changeStep(this.step + 1)
      } else {
        this.scrollToErrorMessage()
        this.trackValidationErrors()
      }
      e.preventDefault()
      return false
    }

    if (this.hasPreviewExperimentSet() && !isFormValid) {
      this.scrollToErrorMessage()
      this.trackValidationErrors()
      e.preventDefault()
      return false
    }

    this.changeNextButtonsState(false)
    this.mergeAndSubmitStreetAddress()
    if (this.isForCustomVendor) this.mergeAndSubmitCustomVendorStreetAddress()
    this.setFormFlowType()
    this.appendAdditionalRequestsToLetterText()
    AttachmentUploader.clearSessionStorage()
    Amplitude.logEvent('CS Form Page Submitted Cancellation')
    return true
  }

  vwoStepAcknowledge() {
    if (this.step === 1) return VWO.triggerEvent(240)

    VWO.triggerEvent(241)
  }

  changeNextButtonsState(enabled) {
    ;[this.nextButton, this.duplicatedNextButton].forEach((button) => {
      enabled ? button.removeAttribute('disabled') : button.setAttribute('disabled', true)
    })
  }

  letterPreviewClickHandler(e) {
    const letterPreviewDataPrefix = 'preview-letter-'
    const dataContext = e.target.dataset.context
    if (!dataContext || !dataContext.includes(letterPreviewDataPrefix)) return

    const filteredList = this.cancellationFormFields.filter((field) => field.step === 1)
    const firstStepInputFields = filteredList.map((field) => field.name)
    const resolvedInputName = dataContext.replace(letterPreviewDataPrefix, '')
    const stepToGo = firstStepInputFields.includes(resolvedInputName) ? 1 : 2
    this.inputToBeFocused = resolvedInputName
    this.changeStep(stepToGo)
  }

  cancellationDropdownCallback() {
    const cancellationDropdownHasChanged = this.selectedCancellationType !== this.cancellationTypeDropdown.value()
    this.displayCancellationGuaranteeOnInfoText()

    this.selectedCancellationType = this.cancellationTypeDropdown.value()
    this.el.dispatchEvent(new CustomEvent('aboalarm:dropdownChange'))
    this.displayCancellationTypeFields()
    this.setLetterSubjectAndText()

    if (cancellationDropdownHasChanged) {
      this.persistFieldValues()
      this.trackContractTypeChange()
    }
  }

  displayCancellationGuaranteeOnInfoText() {
    const caGuEl = this.el.querySelector('[data-context="info-box-cancellation-guarantee"]')
    if (caGuEl.dataset.hasCagu === 'false') return
    const isRegularCancellation = this.selectedCancellationType === 'regular'
    const displayCaGuMethod = isRegularCancellation ? ShowElement : HideElement
    const hideSpecialCaGuMethod = isRegularCancellation ? HideElement : ShowElement
    displayCaGuMethod(caGuEl)
    displayCaGuMethod(this.el.querySelector('[data-context="cagu-info-text-regular"]'))
    hideSpecialCaGuMethod(this.el.querySelector('[data-context="cagu-info-text-special"]'))
  }

  mergeAndSubmitStreetAddress() {
    const streetValue = this.contactStreet.value()
    const houseNumberValue = this.contactStreetNumber.value()
    this.el.querySelector('[data-context="contact-address"]').value = `${streetValue} ${houseNumberValue}`
  }

  mergeAndSubmitCustomVendorStreetAddress() {
    const streetValue = this.customVendorStreet.value()
    const houseNumberValue = this.customVendorStreetNumber.value()
    this.el.querySelector('[data-context="custom-vendor-address"]').value = `${streetValue} ${houseNumberValue}`
  }

  setFormFlowType() {
    const flowType = this.flowType() === 'withdrawal' ? 'widerruf' : 'kuendigungsschreiben'
    this.el.querySelector('[data-context="aboalarm-flow-type"]').value = flowType
  }

  appendAdditionalRequestsToLetterText() {
    if (this.blockPhoneRequestForLetterCopy) {
      this.el.querySelector('[data-context="letter-text-field"]').value = `${this.letterText.value()}\n\n${
        this.blockPhoneRequestForLetterCopy
      }`
    }
  }

  closeButtonCallback() {
    window.dispatchEvent(new CustomEvent('aboalarm:closeCancellationModal'))
  }

  listenBlockPhoneNumberSelection() {
    if (!this.el.querySelector('[data-context="block-phone-number-checkbox"]')) return

    const phoneNumberLetterText = this.el.querySelector('[data-context="block-phone-number-letter-text"]')
    let trackedEvent = false
    const displayRadioButtons = () => {
      resetRadioButtons()
      if (this.blockPhoneNumberCheckbox.value()) {
        enableRadioButtons()
        ShowElement(this.blockPhoneNumberAsap.el)
        ShowElement(this.blockPhoneNumberEnd.el)
        preSelectRadioOption()
        if (!this.fields.includes('blockPhoneNumberCheckbox')) this.fields.push('blockPhoneNumberCheckbox')
        if (!this.fields.includes('blockPhoneRequestForLetterCopy')) this.fields.push('blockPhoneRequestForLetterCopy')
        if (!trackedEvent) {
          Amplitude.logEvent('CS Form Step Add Request', { addedRequest: 'Ich möchte meine Rufnummer mitnehmen' })
          trackedEvent = true
        }
      } else {
        omitUserChoiceFromLetterText()
        HideElement(this.blockPhoneNumberAsap.el)
        HideElement(this.blockPhoneNumberEnd.el)
      }
    }
    const applyUserChoiceToLetterText = (e, valueSet) => {
      if ((e && e.type === 'pageshow') || !valueSet) return

      this.blockPhoneRequestForLetterCopy = valueSet
      phoneNumberLetterText.innerText = valueSet
      ShowElement(phoneNumberLetterText)
      this.persistFieldValues()
    }
    const omitUserChoiceFromLetterText = () => {
      this.blockPhoneRequestForLetterCopy = null
      HideElement(phoneNumberLetterText)
      this.blockPhoneNumberAsap.inputEl.setAttribute('disabled', true)
      this.blockPhoneNumberEnd.inputEl.setAttribute('disabled', true)
    }
    const resetRadioButtons = () => {
      this.blockPhoneNumberAsap.inputEl.checked = false
      this.blockPhoneNumberEnd.inputEl.checked = false
    }
    const enableRadioButtons = () => {
      this.blockPhoneNumberAsap.inputEl.removeAttribute('disabled')
      this.blockPhoneNumberEnd.inputEl.removeAttribute('disabled')
    }
    const showGivenRadioAsSelected = (iaInstance) => {
      iaInstance.inputEl.checked = true
      DispatchEventByName('change', iaInstance.inputEl)
    }
    const preSelectRadioOption = () => {
      if (
        this.blockPhoneRequestForLetterCopy &&
        this.blockPhoneRequestForLetterCopy !== this.blockPhoneNumberAsap.value()
      )
        return showGivenRadioAsSelected(this.blockPhoneNumberEnd)

      showGivenRadioAsSelected(this.blockPhoneNumberAsap)
    }
    this.blockPhoneNumberCheckbox = new InputAboalarm('block-phone-number-checkbox', () => {
      displayRadioButtons()
      this.persistFieldValues()
    })
    this.blockPhoneNumberAsap = new InputAboalarm('block-phone-number-asap', (e) => {
      if (!e && !this.blockPhoneNumberAsap.inputEl.checked) return
      applyUserChoiceToLetterText(e, this.blockPhoneNumberAsap.value())
    })
    this.blockPhoneNumberEnd = new InputAboalarm('block-phone-number-end', (e) => {
      if (!e && !this.blockPhoneNumberEnd.inputEl.checked) return
      applyUserChoiceToLetterText(e, this.blockPhoneNumberEnd.value())
    })
  }

  letterTextSubjectCallback() {
    const originalLetterTextIsPersisted = () => this.letterText.value().includes(this.letterTextareaDefault)
    const originalLetterSubjectIsPersisted = () => this.letterSubject.value().includes(this.letterSubjectDefault)
    const methodToDisplayResetTextButton =
      originalLetterTextIsPersisted() && originalLetterSubjectIsPersisted() ? HideElement : ShowElement
    this.el.querySelector('[data-context="termination-text-modified"]').value = !originalLetterTextIsPersisted()
    methodToDisplayResetTextButton(this.resetTextBox)
  }

  resetCancellationTextButtonCallback() {
    this.letterSubject.inputEl.value = this.letterSubjectDefault
    this.letterText.inputEl.value = this.letterTextareaDefault
    HideElement(this.resetTextBox)
  }

  changeStep(newStep) {
    if (newStep === this.step) return
    this.step = newStep
    this.el.dispatchEvent(new CustomEvent('aboalarm:stepChange'))
    if (this.step === 2) {
      this.resizeTerminationTextArea()
    }
  }

  listenStepChange() {
    this.el.addEventListener('aboalarm:stepChange', () => {
      this.adjustUIforStepChange()
      this.trackCurrentStep()
    })
  }

  renderingProgressBar() {
    const progressBar = document.querySelector('[data-context="progress-bar"]')
    progressBar.style.setProperty('--progressBarValue', this.step * 25)
  }

  adjustUIforStepChange() {
    this.displayBackButton()
    this.changeNextButtonsState(true)
    this.scrollTop()
    if (this.step === 3) this.renderPreviewLetter()

    this.pristine = true
    this.el.dataset.currentStep = this.step
    console.log('BackstopJS Transition has successfully ended.')
  }

  autoFillCityCountry() {
    const zipcode = this.contactZipcode.value()
    if (!zipcode || zipcode.length < 4) return

    this.zipcodeToCityResolver(zipcode).then((res) => {
      if (!res || !res.results) return

      const firstSelection = res.results[0]
      if (this.contactCountry.valueRepresentation() !== firstSelection.country) {
        this.changeCountry(firstSelection.country)
      }
      this.fillCityIn(firstSelection.city)
    })
  }

  async zipcodeToCityResolver(zipcode) {
    const url = this.el.dataset.zipcodeSearchUrl + zipcode
    try {
      const response = await fetch(url)
      if (!response.ok) {
        if (response.error)
          throw new Error(`Response status: ${response.status}, Response message: ${response.errors[0]}`)
      }

      return await response.json()
    } catch (error) {
      console.error(error.message)
    }
  }

  fillCityIn(resolvedCity) {
    this.contactCity.setValue(resolvedCity)
  }

  changeCountry(resolvedCountry) {
    const matchingCountryOption = [...this.contactCountry.inputEl.options].filter((el) => el.text === resolvedCountry)
    this.contactCountry.setValue(matchingCountryOption[0].value)
  }

  scrollTop() {
    const bufferInterval = this.isMobileFlow ? 350 : 100
    CallAnimationFrame(() => {
      this.el.querySelector('[data-context="top-scroll-anchor"]').scrollIntoView({ behavior: 'smooth' })
    }, bufferInterval)
  }

  adjustUIforCancellationTypeSelection() {
    const selectedTypeRequiresContentChange = ['deceased', 'moving']
    const specialCancellationTypeWarningBox = this.el.querySelector('[data-context="special-cancellation-warning"]')
    const allSpecialCancellationTypeLetterPreviewFields = this.el.querySelectorAll(
      '[data-preview-letter-special-cancellation-type]'
    )
    const selectedSpecialCancellationTypeLetterPreviewFields = this.el.querySelectorAll(
      `[data-preview-letter-special-cancellation-type="${this.selectedCancellationType}"]`
    )
    selectedTypeRequiresContentChange.map((typeThatRequireContentChange) => {
      HideElement(this.el.querySelector(`[data-context="${typeThatRequireContentChange}-title"]`))
    })
    Array.from(allSpecialCancellationTypeLetterPreviewFields).map((el) => HideElement(el))
    HideElement(specialCancellationTypeWarningBox)
    if (selectedTypeRequiresContentChange.includes(this.selectedCancellationType)) {
      ShowElement(this.el.querySelector(`[data-context="${this.selectedCancellationType}-title"]`))
      ShowElement(specialCancellationTypeWarningBox)
      specialCancellationTypeWarningBox.querySelector('span').innerHTML = I18n.t(
        `frontend.cancellation_form.special_cancellation_types.${this.selectedCancellationType}_warning`
      )
    }
    if (selectedSpecialCancellationTypeLetterPreviewFields.length) {
      Array.from(selectedSpecialCancellationTypeLetterPreviewFields).map((el) => ShowElement(el))
    }
    this.setInfoBoxCopyChanges()
    this.setStepTwoTitle()
  }

  setInfoBoxCopyChanges() {
    const { vendor } = this
    const identificationFieldsArray = this.el.querySelector('[data-step2-fields]').dataset.step2Fields
    const initialTranslationKey = this.isForCustomVendor
      ? 'custom_vendor_info_box_copy_of_step'
      : 'info_box_copy_of_step'
    const resolvedTranslationKey = (step) => {
      return `${this.flowType()}.${initialTranslationKey}${step}`
    }
    for (let i = 1; i < this.totalSteps + 1; i++) {
      const mappedTranslationKey = resolvedTranslationKey(i)
      this.el.querySelector(`[data-context="info-box-copy-of-step${i}"]`).innerHTML = I18n.t(
        `frontend.cancellation_form.info_boxes.${mappedTranslationKey}`,
        {
          vendor,
          ...{ identification_fields: identificationFieldsArray },
        }
      )
    }
  }

  setStepTwoTitle() {
    const { vendor } = this
    const key = this.isForCustomVendor ? 'custom_step2_title' : 'step2_title'

    this.el.querySelector(
      "[data-context='step-2-title']"
    ).innerHTML = I18n.t(`frontend.cancellation_form.titles.${this.flowType()}.${key}`, { vendor })
  }

  flowType() {
    if (this.selectedCancellationType === 'withdrawal') return 'withdrawal'
    return 'regular'
  }

  setLetterSubjectAndText() {
    const { vendor } = this
    const newLetterLabel = I18n.t(
      `frontend.cancellation_form.special_cancellation_types.letter_text_label.${this.flowType()}`
    )
    const newLetterSubject = I18n.t(
      `frontend.cancellation_form.special_cancellation_types.letter_subject.${this.selectedCancellationType}`,
      { vendor }
    )
    const newLetterText = I18n.t(
      `frontend.cancellation_form.special_cancellation_types.letter_text.${this.selectedCancellationType}`
    )
    this.letterSubject.setValue(newLetterSubject)
    this.letterSubjectDefault = newLetterSubject
    this.letterText.el.dataset.inputLabel = newLetterLabel
    this.letterText.setValue(newLetterText)
    this.letterTextareaDefault = newLetterText
  }

  displayCancellationTypeFields() {
    const specialCancellationTypeNames = this.cancellationTypeList.map((ctObj) => ctObj.name)
    this.hideAllCancellationTypeFields()

    if (specialCancellationTypeNames.includes(this.selectedCancellationType)) {
      this.showSelectedCancellationTypeFields()
    }
    this.adjustUIforCancellationTypeSelection()
  }

  hideAllCancellationTypeFields() {
    const hideFields = (inputFields) =>
      inputFields.forEach((inputField) => {
        HideElement(this.getInputAboalarmElementByName(inputField))
      })
    this.cancellationTypeList.forEach((cancellationType) => {
      hideFields(cancellationType.inputFields)
    })
    this.disposeSelectedCancellationTypeFields()
  }

  showSelectedCancellationTypeFields() {
    const showFields = (inputFields) =>
      inputFields.forEach((inputField) => {
        if (!this.checkboxDependentDeathSpecialInputFields.includes(inputField)) {
          ShowElement(this.getInputAboalarmElementByName(inputField))
        }
      })
    const selectedCancellationList = this.cancellationTypeList.filter(
      (cancellationType) => this.selectedCancellationType === cancellationType.name
    )[0]
    showFields(selectedCancellationList.inputFields)
    CallAnimationFrame(() => this.generateSelectedCancellationTypeFields(selectedCancellationList))
  }

  disposeSelectedCancellationTypeFields() {
    if (this.generatedInputFields && this.generatedInputFields.length) {
      this.generatedInputFields.forEach((generatedInputField) => {
        this[generatedInputField].inputEl.setAttribute('disabled', true)
        this[generatedInputField].destroy()
        delete this[generatedInputField]
      })
    }
    this.generatedInputFields = []
  }

  generateSelectedCancellationTypeFields(selectedCancellationList) {
    selectedCancellationList.inputFields.forEach((inputField) => {
      if (inputField === 'fileInput') return this.enableAttachmentField()

      const dataContextId = lowerCaseToDashCase(inputField)
      const variableName = lowerCaseToKebabCase(inputField)
      const inputCallback = this.generateInputCallback(inputField, selectedCancellationList.inputFields, variableName)
      this[variableName] = new InputAboalarm(dataContextId, inputCallback.bind(this))
      this.generatedInputFields.push(variableName)

      this[variableName].inputEl.removeAttribute('disabled')
      if (this.secondStepInputFields.includes(inputField)) {
        InitInputMaskFor(this[variableName].inputEl)
      }
    })
  }

  generateInputCallback(inputField, inputFields, variableName) {
    const includesField = (field, fieldName) => field.includes(fieldName)
    return () => {
      this.persistFieldValues()
      if (this.secondStepInputFields.includes(inputField)) {
        return this.validateDate(variableName, 'presence_date')
      }
      if (includesField(inputField, 'country_id') || includesField(inputField, 'zipcode')) {
        const zipcodeField = inputFields.find((field) => includesField(field, 'zipcode'))
        const countryField = inputFields.find((field) => includesField(field, 'country_id'))
        return this.generatedInputCountryZipCodeCallback({
          zipcodeField: lowerCaseToKebabCase(zipcodeField),
          countryField: lowerCaseToKebabCase(countryField),
        })
      }
      if (inputField === 'death_address_is_different') {
        this.differentAddressOfDeceasedCallback()
      } else {
        this.validate({ fieldName: variableName })
      }
    }
  }

  generatedInputCountryZipCodeCallback(fieldsObject) {
    if (!this[fieldsObject.zipcodeField] || !this[fieldsObject.countryField]) return true

    return this.validateZipCode(fieldsObject.zipcodeField, fieldsObject.countryField)
  }

  enableAttachmentField() {
    const fileInput = this.getInputAboalarmElementByName('fileInput')
    CallAnimationFrame(() => {
      fileInput.querySelector('input').removeAttribute('disabled')
      fileInput.dataset.inputLabel = I18n.t(
        `frontend.cancellation_form.special_cancellation_types.attachment_labels.${this.selectedCancellationType}`
      )
      fileInput.addEventListener('aboalarm:attachmentUploaderUpdate', (e) => {
        this.changeNextButtonsState(e.detail.status !== 'loading')
      })
    })
  }

  getInputAboalarmElementByName(name) {
    if (name === 'fileInput') return this.el.querySelector('[data-context="attachment-section"]')

    return this.el.querySelector(`[name="cancellation_form[additional_fields_values][${name}]"]`).parentElement
  }

  getCheckedStatusForDifferentAddressOfDeceased() {
    return this['deathAddressIsDifferent'] ? this['deathAddressIsDifferent'].inputEl.checked : false
  }

  differentAddressOfDeceasedCallback() {
    const checkboxChecked = this.getCheckedStatusForDifferentAddressOfDeceased()
    this.checkboxDependentDeathSpecialInputFields.forEach((deathSpecialInputField) => {
      const domEl = this.getInputAboalarmElementByName(deathSpecialInputField)
      checkboxChecked ? ShowElement(domEl) : HideElement(domEl)
    })
  }

  trackCurrentStep() {
    const stepName = this.getStepName()
    let eventsPayload = {
      cancellationFormStep: stepName,
      slug: ANALYTICS_DATA.slug,
      contractType: this.selectedCancellationType,
      brandName: ANALYTICS_DATA.brand,
    }

    GoogleTagManager.pushDataLayerEvent('cancellationFormStepDisplayed', eventsPayload)
    Amplitude.logEvent('CS Form Page Step Displayed', eventsPayload)

    this.renderingProgressBar()
    if (this.inputToBeFocused) {
      CallAnimationFrame(() => {
        this[this.inputToBeFocused].inputEl.focus()
        this.inputToBeFocused = null
      })
    }
  }

  trackContractTypeChange() {
    GoogleTagManager.pushDataLayerEvent('subjectOfAgreementChosen', {
      signatureRequired: this.signatureRequired,
    })
    Amplitude.logEvent('CS Form Step Change Cancellation Reason ', {
      ...this.amplitudeData,
      cancellationType: this.selectedCancellationType,
    })
  }

  resizeTerminationTextArea() {
    const textareaHeight = 134
    const domEl = document.querySelector('[data-context="letter-text-field"]')
    const offset = domEl.offsetHeight - domEl.clientHeight

    if (!domEl) return

    if (domEl.value == '') {
      domEl.setAttribute('style', 'height:' + textareaHeight + 'px;overflow-y:hidden;')
    } else {
      domEl.setAttribute('style', 'height:' + domEl.scrollHeight + 'px; overflow-y: hidden;')
    }
    domEl.addEventListener(
      'input',
      (e) => {
        e.target.style.height = 'auto'
        e.target.style.height = domEl.scrollHeight + offset + 'px'
      },
      false
    )
  }

  listenStopMarketingOffers() {
    const stopMarketingOffersText = this.el.querySelector('[data-name="stop-marketing-offers-text"]')
    let trackedEvent = false
    const checkboxChange = (checked) => {
      if (checked) {
        ShowElement(stopMarketingOffersText)
        this.stopMarketingOfferCheck = stopMarketingOffersText.innerHTML
        if (!this.fields.includes('stopMarketingOffers')) this.fields.push('stopMarketingOffers')
        if (!this.fields.includes('stopMarketingOfferCheck')) this.fields.push('stopMarketingOfferCheck')
        if (!trackedEvent) {
          Amplitude.logEvent('CS Form Step Add Request', {
            addedRequest: 'Ich will keine Angebote oder Werbung von vendor erhalten',
          })
          trackedEvent = true
        }
      } else {
        HideElement(stopMarketingOffersText)
        this.stopMarketingOfferCheck = null
      }
    }

    this.stopMarketingOffers = new InputAboalarm('stop-marketing-offers', () => {
      checkboxChange(this.stopMarketingOffers.value())
      this.persistFieldValues()
    })
    if (this.stopMarketingOfferCheck) {
      this.stopMarketingOffers.inputEl.checked = true
      DispatchEventByName('change', this.stopMarketingOffers.inputEl)
    }
  }

  /**
   * assess whether a given value meets the predefined conditions
   * @param {Object} validationPayload the required parameter that contains all the fields in an object
   * @param {string} validationPayload.fieldName the field name of the controller which is points to an inputAboalarm instance
   * @param {(function()|null)} validationPayload.validationMethod an optional callback function that allows us to define specific criteria and assess whether the value meets these predefined conditions; otherwise just null checks the value
   * @param {(function()|null)} validationPayload.validCallback an optional callback function that will be executed when the input value meets the specified criteria and is considered valid
   * @param {(function()|null)} validationPayload.invalidCallback an optional callback function that will be executed when the input value doesn't meet the specified criteria and is considered invalid
   * @param {(string|null)} validationPayload.errorField an optional field that's mapped to display corresponding validation error message
   * @returns {boolean} the result that indicates whether the value fulfills the predefined conditions
   */
  validate(validationPayload) {
    const field = this[validationPayload.fieldName]
    const trimmedInputValue = field.value().trim()
    const basicValueCheck = () => field.value().trim().length !== 0
    const isValid = validationPayload.validationMethod
      ? validationPayload.validationMethod(trimmedInputValue)
      : basicValueCheck()

    if (isValid) {
      field.hideError()
      field.showValid()
      if (validationPayload.validCallback) validationPayload.validCallback(trimmedInputValue)
      return true
    }

    if (this.pristine) {
      field.hideValid()
    } else {
      const validationErrorField = validationPayload.errorField ? validationPayload.errorField : 'presence'
      field.showError(
        I18n.t(`frontend.cancellation_form.errors.${validationErrorField}`, {
          field_name: field.label(),
        })
      )
    }
    if (validationPayload.invalidCallback) validationPayload.invalidCallback(trimmedInputValue)
    return false
  }

  validateEmail() {
    const validCallback = (trimmedInputValue) => {
      this.contactEmail.inputEl.value = trimmedInputValue
    }
    return this.validate({
      fieldName: 'contactEmail',
      validationMethod: IsValidEmailAddress,
      validCallback,
      errorField: 'presence_email',
    })
  }

  mapIdentificationFieldsToValidationFieldObjects() {
    return Array.from(this.identificationFields).map((identificationField) => {
      const field = this[identificationField]
      return {
        field,
        isRequired: field.isRequired(),
        hasValue: !!field.value(),
      }
    })
  }

  updateIdentificationFieldsValidity() {
    const validationFieldObjects = this.mapIdentificationFieldsToValidationFieldObjects()
    const hasAtLeastOneFilledInField = validationFieldObjects.some((vfo) => vfo.hasValue)
    validationFieldObjects.forEach((vfo) => {
      if (vfo.isRequired && vfo.hasValue) return vfo.field.hideError()

      if (!vfo.hasValue) vfo.field.hideValid()
      if (!vfo.isRequired && (this.minimumFieldsRequired === 0 || hasAtLeastOneFilledInField)) vfo.field.hideError()
    })
  }

  introduceStepValidations() {
    this.firstStepValidations = [
      this.validateContactFields.bind(this),
      this.validateSpecialCancellationTypeFieldsOfFirstStep.bind(this),
    ]
    this.secondStepValidations = [
      this.validateCustomVendorFields.bind(this),
      this.validateCancellationContractFields.bind(this),
      this.validateSpecialCancellationTypeFieldsOfSecondStep.bind(this),
    ]
    if (this.signatureRequired) this.secondStepValidations.push(this.validateSignature.bind(this))
  }

  validateForm() {
    switch (this.step) {
      case 1:
        return this.firstStepValidations.map((fsv) => fsv()).every((bool) => bool)
      case 2:
        return this.secondStepValidations.map((ssv) => ssv()).every((bool) => bool)
      default:
        return true
    }
  }

  validateContactFields() {
    const contactFields = [
      this.validate({ fieldName: 'contactFirstName', errorField: 'presence_firstname' }),
      this.validate({ fieldName: 'contactLastName', errorField: 'presence_lastname' }),
      this.validate({ fieldName: 'contactStreet', errorField: 'presence_street' }),
      this.validate({ fieldName: 'contactStreetNumber', errorField: 'presence_street_number' }),
      this.validate({ fieldName: 'contactCity', errorField: 'presence_city' }),
      this.validateZipCode('contactZipcode', 'contactCountry'),
      this.validate({ fieldName: 'contactCountry' }),
      this.validateEmail(),
    ]
    return contactFields.every((contactFieldValidation) => contactFieldValidation)
  }

  validateCancellationContractFields() {
    let cancellationContractFields = [
      this.validate({ fieldName: 'letterSubject' }),
      this.validate({ fieldName: 'letterText' }),
    ]
    if (this.contractOwnerBirthDate)
      cancellationContractFields.push(this.validateDate('contractOwnerBirthDate', 'presence_date_of_birth'))
    if (this.identificationFields.length) {
      this.identificationFields.map((identificationField) => {
        cancellationContractFields.push(this.validateIdentificationField(identificationField))
      })
    }
    return cancellationContractFields.every(
      (cancellationContractFieldValidation) => cancellationContractFieldValidation
    )
  }

  validateSignature() {
    if (!this.pristine) this.signatureUploader.updateWarning()
    return this.signatureUploader.getValidStatus()
  }

  validateZipCode(fieldName, countryFieldName) {
    let valid = true
    const countryDropdown = this[countryFieldName].inputEl
    const countryDropdownSelectedOption = countryDropdown[countryDropdown.selectedIndex]
    const selectedCountryId = countryDropdownSelectedOption.dataset.countryCode
    const field = this[fieldName]
    if (validateZipcodeForCountryForAboalarm(field.value().replace(/\s+/g, ''), selectedCountryId)) {
      field.hideError()
      field.showValid()
    } else {
      valid = false
      field.hideValid()
      if (!this.pristine) {
        field.showError(fetchZipcodeErrorMessageForCountry(selectedCountryId))
      }
    }
    return valid
  }

  validateIdentificationField(fieldName) {
    const field = this[fieldName]
    const validationMethod = (trimmedInputValue) => {
      if (this.minimumFieldsRequired === 0) return field.isRequired() ? trimmedInputValue : true
      return field.isRequired() ? trimmedInputValue : this.identificationFields.some((id) => this[id].value())
    }
    return this.validate({
      fieldName,
      validationMethod,
      validCallback: this.updateIdentificationFieldsValidity.bind(this),
      errorField: 'presence_identification',
    })
  }

  validateDate(fieldName, mappedErrorField) {
    if (fieldName.includes('BirthDate')) {
      const validationMethod = (arg) => IsValidDate(arg) && IsValidYear(arg)
      const invalidCallback = () => {
        const field = this[fieldName]
        if (!field.value() || this.pristine) return
        field.showError(
          I18n.t(`frontend.cancellation_form.errors.presence_invalid_date_of_birth`, {
            field_name: field.label(),
          })
        )
      }
      return this.validate({
        fieldName,
        validationMethod,
        invalidCallback,
        errorField: mappedErrorField,
      })
    } else {
      const validationMethod = (arg) => IsValidDate(arg)
      return this.validate({
        fieldName,
        validationMethod,
        errorField: mappedErrorField,
      })
    }
  }

  scrollToErrorMessage() {
    const errorPointer = this.form.querySelector('.c-form-element__feedback--is-invalid')
    if (errorPointer) return errorPointer.parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' })

    console.info('Missing error pointer')
    if (window['Rollbar']) Rollbar.error('Missing error pointer', errorPointer)
  }

  trackValidationErrors() {
    let fieldsWithError = []
    const gatherError = (field) => {
      const fieldName = field.name()
      fieldsWithError.push(fieldName)
      GoogleTagManager.pushDataLayerEvent('validationErrorDisplayed', {
        failedInput: fieldName,
        failedMessage: field.errorMessage(),
      })
    }
    const filteredFields = this.fields.filter((field) => typeof this[field] === 'object' && this[field] !== null)
    filteredFields.filter((field) => this[field].isInvalid()).forEach((field) => gatherError(this[field]))
    if (fieldsWithError.length) {
      Amplitude.logEvent('CS Form Page Validation Error', {
        ...this.amplitudeData,
        validationErrorLabel: fieldsWithError,
      })
    }
  }

  /**
   * get current or given step's name
   * @param {number|string} step an optional parameter; set it if you'd like to get the name of a given step
   * @returns {string} the resolved step name
   */
  getStepName(step) {
    const stepNames = ['Contact Details Step', 'Contract Details Step', 'Cancellation Letter Step']
    const resolvedStep = step ? step : this.step - 1
    return stepNames[resolvedStep]
  }

  validateCustomVendorFields() {
    if (!this.isForCustomVendor) return true

    const customVendorFields = [
      this.validate({ fieldName: 'customVendorName' }),
      this.validate({ fieldName: 'customVendorStreet' }),
      this.validate({ fieldName: 'customVendorStreetNumber' }),
      this.validate({ fieldName: 'customVendorCity' }),
      this.validateZipCode('customVendorZipcode', 'customVendorCountry'),
      this.validate({ fieldName: 'customVendorCountry' }),
    ]
    return customVendorFields.every((customVendorFieldValidation) => customVendorFieldValidation)
  }

  validateSpecialCancellationTypeFieldsOfFirstStep() {
    if (!this.generatedInputFields || this.generatedInputFields.length === 0) return true

    const secondStepInputInstanceNames = this.secondStepInputFields.map((ssif) => lowerCaseToKebabCase(ssif))
    const fieldsNotToBeValidated = ['deathAddressIsDifferent', ...secondStepInputInstanceNames]
    const deathSpecialInputInstanceNames = this.checkboxDependentDeathSpecialInputFields.map((cddsif) =>
      lowerCaseToKebabCase(cddsif)
    )
    const filterMandatoryFields = (generatedInputField) =>
      !deathSpecialInputInstanceNames.includes(generatedInputField) ||
      (this.getCheckedStatusForDifferentAddressOfDeceased() &&
        deathSpecialInputInstanceNames.includes(generatedInputField))
    const filteredCancellationTypeInputFields = this.generatedInputFields
      .filter((generatedInputField) => !fieldsNotToBeValidated.includes(generatedInputField))
      .filter(filterMandatoryFields)
    const cancellationTypeInputFields = filteredCancellationTypeInputFields.map((generatedInputField) => {
      if (generatedInputField.includes('Zipcode')) {
        const countryField = filteredCancellationTypeInputFields.filter((fctif) => fctif.includes('CountryId'))[0]
        return this.validateZipCode(generatedInputField, countryField)
      }
      return this.validate({ fieldName: generatedInputField })
    })
    return cancellationTypeInputFields.every(
      (cancellationTypeInputFieldValidation) => cancellationTypeInputFieldValidation
    )
  }

  validateSpecialCancellationTypeFieldsOfSecondStep() {
    if (!this.generatedInputFields || this.generatedInputFields.length === 0) return true

    const secondStepInputInstanceNames = this.secondStepInputFields.map((ssif) => lowerCaseToKebabCase(ssif))
    const cancellationTypeInputFields = this.generatedInputFields.filter((generatedInputField) =>
      secondStepInputInstanceNames.includes(generatedInputField)
    )
    return cancellationTypeInputFields.every((generatedInputField) =>
      this.validateDate(generatedInputField, 'presence_date')
    )
  }

  persistFormFieldData() {
    if (!this.localStorageAvailable) return

    let fieldsToBeStored = Array.from(this.fields)
    const filterAndPushFields = (type) => {
      const filteredList = this.cancellationFormFields.filter((field) => field.type === type)
      filteredList.forEach((field) => fieldsToBeStored.push(field.name))
    }
    if (this.identificationFields.length) {
      this.identificationFields.map((id) => fieldsToBeStored.push(id))
    }
    if (this.isForCustomVendor) {
      filterAndPushFields('custom')
    }
    if (['moving', 'deceased', 'damage'].includes(this.selectedCancellationType)) {
      filterAndPushFields(this.selectedCancellationType)
    }

    let storedFields = {
      scope: window.location.pathname,
      timestamp: Date.now(),
      data: {},
    }
    fieldsToBeStored.forEach((fieldName) => {
      if (['blockPhoneRequestForLetterCopy', 'stopMarketingOfferCheck'].includes(fieldName))
        return (storedFields.data[fieldName] = this[fieldName])
      if (this[fieldName]) storedFields.data[fieldName] = this[fieldName].value()
    })
    storedFields.data.signatureData = this.signatureUploader ? this.signatureUploader.getSignatureData() : null
    localStorageSetItem('cancellation_fields', JSON.stringify(storedFields))
  }

  fillInFormFieldData() {
    let storedFields = this.localStorageAvailable ? JSON.parse(localStorageGetItem('cancellation_fields')) : null

    if (!IsStoredFieldsValid(storedFields) || !this.localStorageAvailable) return this.clearStoredFields()

    if (storedFields.data.cancellationTypeDropdown !== 'regular') {
      this.cancellationTypeDropdown.setValue(storedFields.data.cancellationTypeDropdown)
      delete storedFields.data.cancellationTypeDropdown
    }

    this.el.addEventListener(
      'aboalarm:dropdownChange',
      () => {
        CallAnimationFrame(() => {
          for (var fieldName in storedFields.data) {
            if (fieldName === 'signatureData') {
              if (this.signatureUploader) this.signatureUploader.fillSignatureData(storedFields.data.signatureData)
            } else if (['blockPhoneRequestForLetterCopy', 'stopMarketingOfferCheck'].includes(fieldName)) {
              this[fieldName] = storedFields.data[fieldName]
            } else if (!this[fieldName].value() && storedFields.data[fieldName].length > 0) {
              this[fieldName].setValue(storedFields.data[fieldName])
            }
          }
          if (this.userSignedIn) return

          this.checkStep()
            .then((resolvedStep) => {
              this.changeStep(resolvedStep)
            })
            .catch((e) => {
              if (window['Rollbar']) Rollbar.error('Error while checking step', e)
            })
        }, 15)
      },
      { once: true }
    )
  }

  async checkStep() {
    const resolvedStep = (resolvedStep) => {
      return new Promise((resolve) => {
        resolve(resolvedStep)
      })
    }
    const shouldBeInTheFirstStep = !this.firstStepValidations.map((fsv) => fsv()).every((bool) => bool)
    const shouldBeInTheSecondStep = !this.secondStepValidations.map((ssv) => ssv()).every((bool) => bool)

    if (shouldBeInTheFirstStep) return resolvedStep(1)
    if (shouldBeInTheSecondStep) return resolvedStep(2)
    if (this.hasPreviewExperimentSet()) return resolvedStep(2)

    return resolvedStep(3)
  }

  clearStoredFields() {
    if (!this.localStorageAvailable) {
      return
    }
    localStorageRemoveItem('cancellation_fields')
  }

  listenQueryParameters() {
    const vendorName = GetUrlParameter(location.href, 'anbieter')
    if (!vendorName) return
    this.customVendorName.inputEl.value = vendorName
    this.customVendorName.blurCallback()
  }

  destroy() {
    this.unsubscribeEvents()
    CallAnimationFrame(() => {
      this.el.replaceWith(this.el.cloneNode(true))
    })
  }
}

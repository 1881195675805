export class NavigationMenu {
  constructor() {
    this.el = document.querySelector('[data-context="navigation-menu"]')
    if (!this.el) return

    this.init()
  }

  init() {
    this.mobileCta = this.el.querySelector('[data-context="navigation-menu-mobile-cta"]')
    this.navigationPopupVisible = false
    this.mobileCta.addEventListener('click', this.toggleNavigationPopup.bind(this))
    this.listenToggleDropdown()
  }

  toggleNavigationPopup() {
    this[`${this.navigationPopupVisible ? 'hide' : 'show'}NavigationPopup`]()
  }

  listenToggleDropdown() {
    Array.from(this.el.querySelectorAll('.c-nav__link--button')).forEach((toggleButton) => {
      toggleButton.addEventListener('click', (e) => {
        const inversedVisibility = e.target.getAttribute('aria-expanded') === 'true' ? 'false' : 'true'
        const inversedDisplayMethod = e.target.classList.contains('is-active') ? 'remove' : 'add'
        e.target.classList[inversedDisplayMethod]('is-active')
        e.target.setAttribute('aria-expanded', inversedVisibility)
      })
    })
  }

  showNavigationPopup() {
    this.el.classList.add('c-nav--open')
    document.body.setAttribute('data-c-dialog', 'open')
    this.navigationPopupVisible = true
  }

  hideNavigationPopup() {
    this.el.classList.remove('c-nav--open')
    document.body.setAttribute('data-c-dialog', 'closed')
    this.navigationPopupVisible = false
  }
}
